import React, { useEffect, useState } from "react";
import aboutBg from "../../images/AboutHeader/MaskAbout.webp";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import PageMain from "../../components/PageMain";
import AboutMilestones from "../../components/About/AboutMilestones";
import AboutStorySection from "../../components/About/AboutStorySection/AboutStorySection";
import AboutTeam from "../../components/About/AboutTeam";
import AboutSimply from "../../components/About/AboutSimply";
import AboutCertified from "../../components/About/AboutCertified";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import aboutData from "../../aboutData.json";
import { ServiceManager } from "../../config/serviceManager";
import TitleComponent from "../../components/titleComponent";

const AboutUs = () => {
  const storySectionData = aboutData.storySection[0];
  const { AboutHeader,milestoneArray,teamData,missionVisionPurpose,membershipsCertifications } = aboutData;

 
  const[recievedAboutHeaderData,setRecievedAboutHeaderData]= useState([]);
   
  useEffect(() => {
    const getAboutHeaderData= async () => {
      try {
        const aboutHeaderResponse = await ServiceManager.getAboutHeaderData(
            1,
        );
                      
        if (!aboutHeaderResponse.hasError) {
          const data = aboutHeaderResponse?.response?.data;
          if(data){
            setRecievedAboutHeaderData(data)
          }
                   
        }else {
          throw new Error("Error in getting aboutUs header data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAboutHeaderData();
  }, []);


  
  return (
    <>
      <ServicesNavbar />
      {recievedAboutHeaderData?<TitleComponent image={recievedAboutHeaderData.bg_image} title={recievedAboutHeaderData.title} description={recievedAboutHeaderData.sub_title}
/> : null}
     
      {storySectionData?<AboutStorySection storyData={storySectionData} />:null}
      {milestoneArray?<AboutMilestones milestoneArray={milestoneArray} />:null}
      {teamData?<AboutTeam teamData={teamData} />:null}
      {missionVisionPurpose?<AboutSimply missionVisionPurpose={missionVisionPurpose} />:null}
      {membershipsCertifications?<AboutCertified membershipsCertifications={membershipsCertifications} />:null}
      <Form />
      <Footer />
    </>
  );
};

export default AboutUs;
