import axios from "./axios";

const post = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios
      .post(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const get = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios 
      .get(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const put = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios
      .put(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const DELETE = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const patch = (api, data, config) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(api, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { post, get, put, DELETE, patch};
