import React, { useEffect, useState } from 'react';
import './AboutStorySection.css';
import { ServiceManager } from '../../../config/serviceManager';
import { MediaUrlBase } from '../../../utils/urls';

function AboutStorySection() {
  const [storyData, setStoryData] = useState(null);
 
  useEffect(() => {
    const getAboutusData = async () => {
      try {
        const aboutUsResponse = await ServiceManager.getOurStory();
         if (!aboutUsResponse?.hasError) {
          const data = aboutUsResponse?.response?.data;
          setStoryData(data);
        } else {
          throw new Error('Error in fetching about us data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAboutusData();
  }, []);

  // if (!storyData) {
  //   return <div>Loading...</div>;
  // }

 const renderImage = `<img 
 align="left"
 src="${MediaUrlBase}${storyData?.image}"
 class="OurStoryImage"
 alt="${storyData?.alt_img_text}"
 loading="lazy"
/>`;




const renderDescription = `${renderImage}<h2>${storyData?.title}</h2>${storyData?.description}`;

  return (
    <>
      {!!storyData && <div className="container aboutnew-story-bg">
      <div className='py-5' >
              <div className='story-description'  dangerouslySetInnerHTML={{ __html: renderDescription }} />
          </div>
    </div>}
    
    </>
   
  );
}

export default AboutStorySection;



