import React, {useState, useEffect} from 'react';
import './../../css/Achievements.css';
import {ServiceManager} from '../../config/serviceManager';
import { MediaUrlBase } from '../../utils/urls';
import { htmlToText } from '../../utils/helpers';
import { Swiper ,SwiperSlide ,useSwiper} from 'swiper/react';
import {Autoplay, FreeMode} from 'swiper/modules';

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const Achievements = () => {
 
  const [autoplay, setAutoplay] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [achievementDataRecieved, setAchievementDataRecieved] = useState([]);
  const [achievementTitleRecieved, setAchievementTitleRecieved] = useState([]);
 


  const handleCardClick = index => {
    setCurrentIndex(index);
  };

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);


  useEffect(() => {
    const getAchievementTitleData = async () => {
      try {
        const getAchievementResponse = await ServiceManager.getAchievementsTitle(1);
          if (!getAchievementResponse.hasError) {
          const data = getAchievementResponse?.response?.data;
          if (data) {
            setAchievementTitleRecieved(data);
          } 
        } else {
          throw new Error('Error in getting achievements data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAchievementTitleData();
  }, []);



  useEffect(() => {
    const getAchievementData = async () => {
      try {
        const getAchievementResponse = await ServiceManager.getAchievementsData(
           1,
        );
        if (!getAchievementResponse.hasError) {
          const data = getAchievementResponse?.response?.data;
          if (data?.length) {
            const newResponse = data
            const sortedResponse = newResponse?.sort((a, b) => {
              return a.order_by - b.order_by;
            })
            setAchievementDataRecieved(sortedResponse);
          } 
        } else {
          throw new Error('Error in getting achievements data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAchievementData();
  }, []);

  return (
    !!achievementDataRecieved?.length && (
      <>
        <div className="container achievement-heading-sized-one achie-space-marg">
         {!!achievementTitleRecieved && <div className="col-lg-12">
            <div className="achiev-heading-width text-center" dangerouslySetInnerHTML={{__html:achievementTitleRecieved[0]?.title}}></div>
            </div>}
        </div>


       { achievementDataRecieved?.length>3 ?

        <div className="container-fluid achie-space-marg-second"  style={{width:"97%"}}>
            <div className="row">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  
                }}
                loop={true}
                navigation={false}
                modules={[Autoplay]}
                className="h-100"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  576: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                }}
                onSlideChange={() => setAutoplay(true)}
               >
                {[...achievementDataRecieved,...achievementDataRecieved]?.map((achievement, index) => (
                  <SwiperSlide key={index}>
                    <div className="highlights-f-mode marquee">
                      <div
                        className={`col-lg card-wrap-second${
                          index === currentIndex ? ' active' : ''
                        }`}
                        key={index}
                        onClick={() => handleCardClick(index)}>
                        <div
                          className={` ${
                            index === currentIndex ? ' active' : ''
                          }`}>
                          <img
                            src={`${MediaUrlBase}${achievement?.achievements_icon}`}
                            className="achievement-bg-first"
                            alt="Achievement"
                            style={{width:"900px",maxWidth:"100%"}}
                            loading="lazy"
                          />
                          <h5
                            className="pt-3 fw-bold"
                            dangerouslySetInnerHTML={{__html: achievement?.achievements_subtitle}}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="w-100 d-flex justify-content-center align-items-center achie-button">
                  <SlidePrevButton />
                  <SlideNextButton />
                </div>
              </Swiper>
            </div>
          </div>:
          
        <div className="row p-4 d-flex">
        {achievementDataRecieved?.map(achievement => (
          <div className="col col-lg-4" key={achievement?.id}>
            <div className="achievement-bg-first">
              <div className="achievement-card">
                <img
                  src={`${MediaUrlBase}${achievement?.achievements_icon}`}
                  alt={achievement?.alt_img_text}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="achievement-bg-first-text pt-1">
              <div className="text-center achie-head-sub" dangerouslySetInnerHTML={{__html:achievement?.achievements_subtitle}}>
                                </div>
            </div>
            <p className="text-center achie-head-sub-one"></p>
          </div>
        ))}
      </div>
}
      </>
    )
  );
};

export default Achievements;
