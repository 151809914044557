import React, { useEffect, useState } from "react";
import styles from "./../../css/Footer.module.scss";
import { ServiceManager } from "../../config/serviceManager";

const Footer = () => {
  const [officeLocationData, setOfficeLocationData] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchOfficeDetails = async () => {
      try {
        const officeResponse = await ServiceManager.getOfficeDetails(1);
        if (!officeResponse.hasError) {
          const data = officeResponse?.response?.data;

          if (data?.length) {
            setOfficeLocationData(data);
          }
        } else {
          throw new Error("Error in fetching office details");
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchOfficeDetails();
  }, []);

  return (
    <>
      <div className={`container`}>
        <div
          className={`row p-md-0 ${styles["center-content"]} justify-content-center`}
        >
          <div
            className={`col-lg-12 text-center ${styles["footer-head-logo"]} mb-5`}
          >
            <img
              src="/images/nav_logo.webp"
              alt="ALSI Global Logo"
              loading="lazy"
            />
          </div>
          {!!officeLocationData?.length &&
            officeLocationData?.map((item) => {
              return (
                <div className="col-lg-3 col-sm-12 col-9 mb-4" key={item?.id}>
                  <div
                    className="center-content-fh mb-2"
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                  ></div>
                  <div
                    className="center-content-fh text-center mb-2"
                    dangerouslySetInnerHTML={{ __html: item?.address }} 
                  ></div>
                  <div className="text-center">
                    <p>{`${item?.phone_number1} ${item?.phone_number2}`}</p>
                  </div>
                  {!!item?.email && (
                    <div className="text-center w-full">
                      <p>
                        <a
                          className="text-decoration-none text-black"
                          href={`mailto:${item?.email}`}
                        >
                          {item?.email}
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className={`row ${styles["footerline-img"]}`}>
        <div className="col-lg-12 text-center">
          <p className={`footer-rights m-0`}>
            © ALSI Global {currentYear}, All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
