import React, {useEffect, useState} from 'react';
import ServicesNavbar from '../../components/Services/ServicesNavbar';
import {Link} from 'react-router-dom';
import Form from '../../components/Home/Form';
import Footer from '../../components/Home/Footer';
import Style from './market.module.scss';
import GetAQuote from '../../components/GetAQuote';
import {ServiceManager} from '../../config/serviceManager';
import TitleComponent from '../../components/titleComponent';
import { htmlToText } from '../../utils/helpers';
import { MediaUrlBase } from '../../utils/urls';

const MarketUpdates = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [blogHeader, setBlogHeader] = useState({});
const [blogData,setBlogData] = useState([]);
const [totalCount,setTotalCount] = useState(0);

const itemsPerPage = 12;

  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    const getMarketHeader = async () => {
      try {
        const marketHeaderResponse = await ServiceManager.getMarketUpdateHeader(1);
         if (!marketHeaderResponse?.hasError) {
          const data = marketHeaderResponse?.response?.data;
            setBlogHeader(data[0]);
        } else {
          throw new Error('Error in fetching Market header');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMarketHeader();
  }, []);


  useEffect(() => {
    const getBlogHeaders = async () => {
      try {
        const blogHeadersResponse = await ServiceManager.getMarketBlogData();
           if (!blogHeadersResponse?.hasError) {
          const data = blogHeadersResponse?.response?.data?.reverse();
          setBlogData(data);
          setTotalCount(data?.length);

        } else {
          throw new Error('Error in fetching career header');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBlogHeaders();
  }, [currentPage]);


  const paginatedData = blogData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div>
      <ServicesNavbar />
{/* <p>{JSON.stringify(blogData)}</p> */}
      {
        <TitleComponent
          title={blogHeader?.market_title}
          image={blogHeader?.market_image}
          description={blogHeader?.market_description}
        />
      }

      <div className="container container-sm container-md container-lg container-xl container-xxl">
        <div className={`row ${Style['market-u-top-h']}`}>
          {paginatedData?.map((card, index) => (
            <div className={`col-md-3 mb-4 `} key={index}>
              <div className={`card p-2 h-100 ${Style['market-update-card']}`}>
                <img
                  src={`${MediaUrlBase}${card?.header_image}`}
                  className="w-100"
                  style={{
                    height: '200px',
                    borderRadius: '7px',
                    objectFit: 'cover',
                  }}
                  alt=""
                />
                <div className="px-2 mt-3 d-flex flex-column">
                  <div className={` ${Style['card-title-wrapper']}`}>
                    <p className="card-title fw-bold">{card?.header_title
}</p>
                    <p className="m-0" style={{fontSize: '13px'}} >{htmlToText(card?.Highlight)?.slice(0,300)}...</p>
                  </div>
                  <div className="py-2">
                    <Link
                      to={`/market-updates/${card.slug}`} state={{blog: card}}
                      style={{
                        color: 'rgb(25, 51, 119)',
                      }}
                      className="d-block py-2 text-decoration-none  fw-semibold">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <nav aria-label="Page navigation" style={{margin: '-30px 0px 70px 0px'}}>
        <ul className="pagination d-flex justify-content-center">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage - 1)}
              style={{
                color: 'black',
                background: 'white',
                outline: 'none',
                border: '0',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.3)',
                transition: 'color 0.3s',
                borderRadius: '5%',
                padding: '13px 20px',
                fontWeight: '600',
              }}>
              Previous
            </button>
          </li>
          {Array.from(
            {length: Math.ceil(totalCount/ itemsPerPage)},
            (_, index) => (
              <li
                className={`page-item ${
                  currentPage === index + 1 ? 'active' : ''
                }`}
                key={index}>
                <button
                  className="page-link"
                  onClick={() => paginate(index + 1)}
                  style={{
                    color: 'black',
                    background: 'white',
                    outline: 'none',
                    border: '0',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.3)',
                    transition: 'color 0.3s',
                    borderRadius: '50%',
                    padding: '13px 22px',
                    fontWeight: '600',
                    margin: '0px 20px',
                  }}
                  onMouseEnter={e => (e.target.style.color = '#007aff')}
                  onMouseLeave={e => (e.target.style.color = 'black')}>
                  {index + 1}
                </button>
              </li>
            ),
          )}
          <li
            className={`page-item ${
              currentPage === Math.ceil(totalCount/ itemsPerPage)
                ? 'disabled'
                : ''
            }`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage + 1)}
              style={{
                color: 'black',
                background: 'white',
                outline: 'none',
                border: '0',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.3)',
                transition: 'color 0.3s',
                borderRadius: '5%',
                fontWeight: '600',
                padding: '13px 20px',
              }}>
              Next
            </button>
          </li>
        </ul>
      </nav>

      <Form />
      <Footer />
      <GetAQuote />
    </div>
  );
};

export default MarketUpdates;
