import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMediaQuery } from "react-responsive";

const Captcha = ({handleCaptchaChange}) => {
	const container = useRef(null);
	const isSmallScreen = useMediaQuery({query: '(max-width: 750px)'});
	const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (container.current) {
        setContainerWidth(container.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

	return (
		<div ref={container}>
			<div>
				<ReCAPTCHA
					style={{
						margin: '5px auto',
						transformOrigin: 'left center',
						transform: `scale(${isSmallScreen ? containerWidth / 400 : 1})`,
					}}
					sitekey={'6Lfqnx8pAAAAANMmnL8PNHoCjMgscRLWJGes1d9Q'}
					onChange={handleCaptchaChange}
				/>
			</div>
		</div>
	);
};

export default Captcha;
