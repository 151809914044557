import React, { Fragment, useEffect } from 'react';
import Lottie from 'lottie-react';
import "../../css/IndustriesCss/IndustriesOil.scss";
import { useState } from "react";
import { ServiceManager } from "../../config/serviceManager";
import LTRCard from "../ImageCards/LTRCard/LTRCard";
import loader from "../../images/lottieAnimation/loader.json";
import { MediaUrlBase } from '../../utils/urls';

const LTR_CARD_LEFT_PANE = {
	style: {
		backgroundSize: "cover",
	},
};

const RTR_CARD_LEFT_PANE = {
	style: {
		background: "transparent",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		color: "black",
	},
}


const IndustriesOil = () => {
	const [loading, setLoading] = useState(true);
	const [industriesData, setIndustriesData] = useState([]);

	useEffect(() => {
		const getIndustriesData = async () => {
			try {
				setLoading(true);
				const responseData = await ServiceManager.getIndustriesData(1);
				if (!responseData?.hasError) {
					const data = responseData?.response?.data;
					if (data?.length) {
						setIndustriesData(data);
					}
				} else {
					throw new Error(responseData?.response);
				}
			} catch (error) {
				console.log({ error });
			} finally {
				setLoading(false);
			}
		};
		getIndustriesData();
	}, []);
	return (
		<div className="my-4 industries">
			{loading ? (
				<>
					<div className="lottieContainer">
						<Lottie
							animationData={loader}
							loop={true}
							style={{ width: '300px', height: '300px' }}
						/>
					</div>
				</>
			) : (
				<div className="container container-sm container-md container-lg container-xl container-xxl ">
					{industriesData.map((data) => {
						return (
							<Fragment key={data?.id}>
								<LTRCard
									title={data?.title}
									description={[data?.description]}
									image={`${MediaUrlBase}${data?.image}`}
									alt_value={data?.alt_img_text}
									leftPane={LTR_CARD_LEFT_PANE}
								/>
							</Fragment>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default IndustriesOil;
