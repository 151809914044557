import React, { useState } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./Header.scss";
import { MediaUrlBase } from "../../utils/urls";

const Header = ({ headerData }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const metaTags = <HelmetProvider><Helmet>
    <title>ALSI - Complete Logistics Solutions</title>
    <meta
      name="description"
      content="ALSI provides reliable, efficient, and seamless logistics solutions. Specializing in customs clearance, transportation, freight forwarding, project cargo services, and warehousing."
    />
    <meta
      name="keywords"
      content="ALSI, Logistics, Customs Clearance, Transportation, Freight Forwarding, Project Cargo, Warehousing"
    />
    <meta name="author" content="Your Name or Company Name" />
    <meta
      property="og:title"
      content="ALSI - Complete Logistics Solutions"
    />
    <meta
      property="og:description"
      content="ALSI provides reliable, efficient, and seamless logistics solutions. Specializing in customs clearance, transportation, freight forwarding, project cargo services, and warehousing."
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.alsiglobal.com/" />
    <meta property="og:image" content="%PUBLIC_URL%/images/og-image.webp" />
    <meta property="og:image:alt" content="ALSI Global Logo" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content="ALSI - Complete Logistics Solutions"
    />
    <meta
      name="twitter:description"
      content="ALSI provides reliable, efficient, and seamless logistics solutions. Specializing in customs clearance, transportation, freight forwarding, project cargo services, and warehousing."
    />
  </Helmet>
  </HelmetProvider>

  return (
    <>
      {metaTags}
      {!!headerData && (
        <header className="header-main" style={{ backgroundImage: `url(${MediaUrlBase}${headerData?.image})` }}>
          <Navbar
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            toggleMenu={toggleMenu}
          />
          <div className="header-container">
            <div className="left-pane">
              <div className="title" dangerouslySetInnerHTML={{ __html: headerData?.title }}></div>
              <div className="subtitle" dangerouslySetInnerHTML={{ __html: headerData?.subtitle }}></div>
              <div className="description" dangerouslySetInnerHTML={{ __html: headerData?.description }}></div>
              <div className="read-more-btn">
                <Link to={headerData.url} className="read-more-btn-link">
                  Read More
                  <div className="icon-arrow">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
