import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SlidePrevButton from "./../Bottons/SlidePrevButton";
import SlideNextButton from "./../Bottons/SlideNextButton";
import "../../css/Services.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ServiceManager } from "../../config/serviceManager";
import { MediaUrlBase } from "../../utils/urls";

const OtherServices = ({ title, servicesPageData, }) => {
	const [isHovered, setIsHovered] = useState(false);
	const sliderRef = useRef(null);
	const [servicesData, setServicesData] = useState([]);

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: !isHovered,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const handleNextClick = () => {
		sliderRef.current.slickNext();
	};

	const handlePrevClick = () => {
		sliderRef.current.slickPrev();
	};

	useEffect(() => {
		const getServicesData = async () => {
			try {
				const serviceResponse = await ServiceManager.getServicesSliderData(1);
				if (!serviceResponse?.hasError) {
					const data = serviceResponse?.response?.data;
					if (data?.length) {
						setServicesData(data);
					}
				} else {
					throw new Error(serviceResponse?.response)
				}
			} catch (error) {
				console.error({ error });
			}
		};
		getServicesData();
	}, []);

	return (
		<>
			<div className="container container-sm container-md container-lg container-xl container-xxl mt-4">
				<h1 className="car-service-cards-heading text-center">{title}</h1>

				<div className="car-service-cards">
					<div className="service-swiper-wrapper mt-5">
						<Slider ref={sliderRef} {...settings} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
							{servicesData.map(({ title, icon, description, link }, index) => (
								<div key={`${title}_index`} className="card card-med p-3">
									<Link to={link} style={{ textDecoration: "none" }}>
										<div className="d-flex justify-content-center align-items-center flex-column w-100 p-3">
											<img
												src={`${MediaUrlBase}${icon}`}
												className="card-img-top"
												width="100"
												alt={title}
												loading="lazy"
												style={{ width: "70px", height: "auto" }}
											/>
										</div>
										<div className="d-flex justify-content-center align-items-center flex-column pt-3">
											<h2>{title}</h2>
											<div style={{ padding: '10px', wordBreak: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center' }} dangerouslySetInnerHTML={{ __html: description }}></div>
										</div>
									</Link>
								</div>
							))}
						</Slider>
						<div className="arrows-service">
							<div className="col-lg-12">
								<div className="col-lg d-flex justify-content-center">
									<SlidePrevButton onClick={handlePrevClick} />
									<SlideNextButton onClick={handleNextClick} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OtherServices;

