import React, { useState, useEffect } from "react";
import { Helmet,HelmetProvider } from "react-helmet-async";
import axios from "axios";

const PageSEO = ({ api }) => {
  const [seoData, setSeoData] = useState({
    charset: "",
    viewport: "",
    title: "",
    description: "",
    keywords: "",
    author: "",
    robots: "",
    copyright: "",
    referrer: "",
    og_title: "",
    og_type: "",
    og_url: "",
    og_image: "",
    og_description: "",
    og_site_name: "",
    og_locale: "",
    twitter_card: "",
    twitter_site: "",
    twitter_title: "",
    twitter_description: "",
    twitter_image: "",
    twitter_image_alt: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(api);
        const data = response.data;
        setSeoData(data);
      } catch (error) {
        console.error(`Error fetching data for API ${api}:`, error);
      }
    };

    fetchData();
  }, [api]);

  return (
    <HelmetProvider>
    <Helmet>

      <meta charSet={seoData.charset} />
      <meta name="viewport" content={seoData.viewport} />
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <meta name="keywords" content={seoData.keywords} />
      <meta name="author" content={seoData.author} />
      <meta name="robots" content={seoData.robots} />
      <meta name="copyright" content={seoData.copyright} />
      <meta name="referrer" content={seoData.referrer || "no-referrer-when-downgrade"} />
      <meta property="og:title" content={seoData.og_title} />
      <meta property="og:type" content={seoData.og_type} />
      <meta property="og:url" content={seoData.og_url} />
      <meta property="og:image" content={seoData.og_image} />
      <meta property="og:description" content={seoData.og_description} />
      <meta property="og:site_name" content={seoData.og_site_name} />
      <meta property="og:locale" content={seoData.og_locale} />
      <meta name="twitter:card" content={seoData.twitter_card} />
      <meta name="twitter:site" content={seoData.twitter_site} />
      <meta name="twitter:title" content={seoData.twitter_title} />
      <meta name="twitter:description" content={seoData.twitter_description} />
      <meta name="twitter:image" content={seoData.twitter_image} />
      <meta name="twitter:image:alt" content={seoData.twitter_image_alt} />
    </Helmet>
    </HelmetProvider>
  );
};

export default PageSEO;