import React, { useEffect, useState } from "react";
import HomeNavbar from "../../components/Home/Navbar";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./../../css/ServicesCss/ServicePageNavbar.scss";
import { Link, useLocation } from "react-router-dom";
import GetAQuote from "../GetAQuote";
import { ServiceManager } from "../../config/serviceManager";

const ServicesNavbar = () => {
	let initialState = {
		Services: false,
	};
	const location = useLocation();
	const [isOpen, setIsOpen] = useState(false);
	const [services, setServices] = useState([]);
	const [isDropdownOpen, setDropdownOpen] = useState(initialState);
	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	let linkStyles = {
		textDecoration: "none",
		color: isOpen ? "white" : "black",
		width: "156px",
		textAlign: "start",
		fontSize: "15px",
		fontFamily: "poppins, sans-serif"
	};

	if (window.innerWidth <= 1340) {
		linkStyles.width = "auto";
		linkStyles.fontSize = "13px";
	}

	if (window.innerWidth <= 1000) {
		linkStyles.width = "auto";
		linkStyles.fontSize = "12px";
	}

	let navLinks = [
		{
			text: "Home",
			link: "/",
			onMouseEnter: () => setDropdownOpen(initialState),
		},
		{
			text: "About Us",
			link: "/about_us",
			onMouseEnter: () => setDropdownOpen(initialState),
		},
		{
			text: "Services",
			onMouseEnter: (e) => {
				setDropdownOpen({ Services: true });
			},
			children: [
				{
					text: "Customs Clearance",
					link: "/services/customs_clearance",
				},
				{
					text: "Road Freight",
					link: "/services/road_freight",
				},
				{
					text: "Ocean Freight",
					link: "/services/ocean_freight",
				},
				{
					text: "Air Freight",
					link: "/services/air_freight",
				},
				{
					text: "Project Cargo Services",
					link: "/services/project_cargo_services",
				},
				{
					text: "Warehousing and Distribution",
					link: "/services/warehousing_and_distribution",
				},
				{
					text: "Vessel Agency",
					link: "/services/vessel_agency",
				},
				{
					text: "Specialized Services",
					link: "/services/specialized_services",
				},
			],
		},
		{
			text: "Our Network",
			link: "/our_network",
		},
		{
			text: "Industries",
			link: "/industries",
			onMouseEnter: () => setDropdownOpen(initialState),
		},
		{
			text: " Market Updates",
			link: "/market-updates",
			onMouseEnter: () => setDropdownOpen(initialState),
		},
		{
			text: " Contact Us",
			link: "/contact_us",
			onMouseEnter: () => setDropdownOpen(initialState),
		},
		{
			text: "Careers",
			link: "/careers",
			onMouseEnter: () => setDropdownOpen(initialState),
		},
	];

	useEffect(() => {
		const getSubServices = async () => {
			try {
				const getHomeHeaderResponse = await ServiceManager.getHomeServiceData();
				if (!getHomeHeaderResponse.hasError) {
					const data = getHomeHeaderResponse?.response?.data;
					if (data) {
						setServices(data);
					}
				} else {
					throw new Error("Error in getting home header data");
				}
			} catch (error) {
				console.error(error);
			}
		};
		getSubServices();
	}, []);

	const handleDropdown = (event) => {
		setDropdownOpen({ ...isDropdownOpen, Services: !isDropdownOpen.Services });
	};
	let navDropDownProps = {
		onMouseEnter: window.screen.width > 992 ? handleDropdown : () => { },
		onClick: window.screen.width > 992 ? () => { } : handleDropdown,
	};
	useEffect(() => {
		const handleScroll = (e) => {
			let navbar = document.querySelector(".service-navbar");
			let socialButtonDiv = document.querySelector(".nav-icons");

			navbar.classList.toggle("sticky", window.scrollY > 0);
			socialButtonDiv.classList.toggle("d-none", window.scrollY > 0);
			setDropdownOpen(initialState);
		};

		window.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		if (!location.hash) {
			window.scrollTo(0, 0);
		}
	}, [location]);

	return (
		<>
			<div className="d-lg-none">
				<HomeNavbar
					menuOpen={isOpen}
					setMenuOpen={setIsOpen}
					toggleMenu={handleToggle}
				/>
			</div>
			<div className="d-none d-lg-block">
				<div className="container-fluid nav-icons">
					<div className="row icons-service-navi">
						<div className="col-lg-12">
							<nav
								className="d-flex justify-content-end"
								style={{ padding: "0 59px" }}
							>
								<a
									href="https://www.facebook.com/alsimarineservice"
									target="_blank"
								>
									<i className="bi-navi bi-facebook"></i>
								</a>
								<a
									href="https://www.instagram.com/alsiformarineservices_official/"
									target="_blank"
								>
									<i className="bi-navi bi-instagram"></i>
								</a>
								<a
									href="https://www.linkedin.com/company/alsi-official/"
									target="_blank"
								>
									<i className="bi-navi bi-linkedin"></i>
								</a>
								<a href="https://twitter.com/ALSI_OFFICIAL" target="_blank">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										className="bi-navi bi-twitter-x"
										viewBox="0 0 16 16"
									>
										<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
									</svg>
								</a>
								<a
									href="https://in.pinterest.com/alsiformarineservicellc/"
									target="_blank"
								>
									<i className="bi-navi bi-pinterest"></i>
								</a>
							</nav>
						</div>
					</div>
				</div>
				<Navbar
					expand="lg"
					className="navbar navbar-expand-lg service-navbar navbar-custom-service w-100 "
				>
					<div className="container-fluid">
						<Navbar.Brand href="/">
							<img
								className="alsi-ser-nav"
								src="/images/nav_logo.webp"
								alt="Alsi Global Logo"
								width="80"
								height="80"
								loading="lazy"
							/>
						</Navbar.Brand>
						<Navbar.Toggle
							style={{
								border: "none",
								borderRadius: "none",
								boxShadow: "none",
							}}
							aria-controls="responsive-navbar-nav "
							className="nav-toggle-option"
							onClick={handleToggle}
						/>
						<Navbar.Collapse
							className={`navbar-second-adjusted`}
							id="responsive-navbar-nav"
						>
							<Nav className="ml-auto serivice-navbar-container">
								{navLinks.map((linkObj, index) => {
									if (linkObj.children && linkObj.children.length > 0) {
										return (
											<React.Fragment key={index}>
												{isDropdownOpen.Services && (
													<div
														className="d-none d-lg-block position-fixed top-0 w-100 h-100"
														style={{ left: 0 }}
														onClick={() => setDropdownOpen(initialState)}
													/>
												)}
												<NavDropdown
													title={
														<Link
															to={linkObj.link ? linkObj.link : "#"}
															style={linkStyles}
														>
															{linkObj.text}
														</Link>
													}
													id="collasible-nav-dropdown"
													show={isDropdownOpen.Services}
													onMouseLeave={() => setDropdownOpen(initialState)}
													key={index}
													{...navDropDownProps}
												>
													{linkObj.children.map((child, childIndex) => (
														<NavDropdown.Item
															as={Link}
															to={child.link}
															key={childIndex}
															style={linkStyles}
														>
															{child.text}
														</NavDropdown.Item>
													))}
												</NavDropdown>
											</React.Fragment>
										);
									} else {
										return (
											<Nav.Link
												as={Link}
												to={linkObj.link}
												style={linkStyles}
												key={index}
												onMouseEnter={linkObj.onMouseEnter}
											>
												{linkObj.text}
											</Nav.Link>
										);
									}
								})}
								<Nav.Link style={linkStyles} className="px-lg-2" href="#">
									<GetAQuote className="service-navi-quote" />
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</div>
				</Navbar>
			</div>
		</>
	);
};

export default ServicesNavbar;
