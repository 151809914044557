import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./css/global.css";
import "./css/inputs.scss";
import "react-tooltip/dist/react-tooltip.css";
import Services from "./components/Home/Services";
import Footer from "./components/Home/Footer";
import Form from "./components/Home/Form";
import Modal from "./components/Modal/Modal";
import ServicesNavbar from "./components/Services/ServicesNavbar";
import CustomsHeader from "./components/CustomsDedicatedBlogs/CustomsHeader";
import CustomsCentered from "./components/CustomsDedicatedBlogs/CustomsCentered";
import ContactUs from "./components/Contact/ContactUs";
import ContactUsForm from "./components/Contact/ContactUsForm";
import OurIndustriesHeader from "./components/Industries/OurIndustriesHeader";
import IndustriesOil from "./components/Industries/IndustriesOil";
import BlogSpecial from "./components/Market/BlogSpecial";
import BlogSpecialHeader from "./components/Market/BlogSpecialHeader";
import Service from "./Page/Service";
import OurNetwork from "./Page/OurNetwork";
import MarketUpdates from "./Page/MarketUpdates";
import MarketUpdatePost from "./Page/MarketUpdates/MarketUpdate";
import Careers from "./Page/Careers";
import NotFound from "./Page/NotFound";
import Home from "./Page/Home";
import AboutUs from "./Page/AboutUs";
import IndustriesPage from "./components/Industries";
import SpecialServicePage from "./components/Services/SpecialService";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="overflow-hidden">
              <Home/>
            </div>
          }
        />

        <Route
          path="/about_us"
          element={
            <div className="overflow-hidden">
              <AboutUs/>
            </div>
          }
        />

        {/* <Route
          path="/services/:type/:dedicated?"
          element={
            <div className="overflow-hidden">
              <Service/>
            </div>
          }
        /> */}


<Route
          path="/services/:slug"
          element={
            <div className="overflow-hidden">
              <Service/>
            </div>
          }
        />

        <Route
          path="/our_network/"
          element={
            <div className="overflow-hidden">
              <OurNetwork />
            </div>
          }
        />

        <Route
          path="/market-updates/"
          element={
            <div className="overflow-hidden">
              <MarketUpdates />
            </div>
          }
        />

        <Route
          path="/market-updates/:type"
          element={
            <>
              <MarketUpdatePost />
            </>
          }
        />

        <Route
          path="/industries"
          element={
            <div className="overflow-hidden">
              <ServicesNavbar />
              <IndustriesPage/>
              <Services />
              <Form />
              <Footer />
            </div>
          }
        />

<Route
          path="/specialized_services"
          element={
            <div className="overflow-hidden">
              <ServicesNavbar />
              <SpecialServicePage/>
              <Form />
              <Footer />
            </div>
          }
        />

        {/* <Route
          path="/market_updates"
          element={
            <>
              <ServicesNavbar />
              <BlogSpecialHeader />
              <BlogSpecial />
              <Form />
              <Footer />
            </>
          }
        /> */}

        <Route
          path="/smooth_sailing_through_customs_clearance_in_oman_a_guide_to_ensuring_compliance_avoiding_penalties_and_navigating_potential_pitfalls"
          element={
            <>
              <ServicesNavbar />
              <CustomsHeader />
              <CustomsCentered />
              <Form />
              <Footer />
            </>
          }
        />

        <Route
          path="/contact_us"
          element={
            <div className="overflow-hidden">
              <ServicesNavbar />
              <ContactUs />
              <ContactUsForm />
              <Footer />
            </div>
          }
        />

        <Route
          path="/modal"
          element={
            <>
              <Modal />
            </>
          }
        />

        <Route
          path="/careers"
          element={
            <div className="overflow-hidden">
              <Careers />
            </div>
          }
        />

        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;