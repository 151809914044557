import React, {useEffect, useState} from 'react';
import ServicesNavbar from '../../components/Services/ServicesNavbar';
import Services from '../../components/Home/Services';
import Industries from '../../components/Home/Industries';
import Form from '../../components/Home/Form';
import Footer from '../../components/Home/Footer';
import Style from './ournetwork.module.scss';
import GetAQuote from '../../components/GetAQuote';
import {ServiceManager} from '../../config/serviceManager';
import TitleComponent from '../../components/titleComponent';
import Network from './Network';


const OurNetwork = () => {
  const [locationHeader, setLocationHeader] = useState({});
   const[locations,setLocations] = useState([]);
  const [offices,setOffices] = useState([]);

  const[currentLocation,setCurrentLocation] = useState({});
  const[officeInLocation,setOfficeInLocation] = useState([]);
   const[headOffices,setHeadOffices] = useState({});
 
  const [activeLocation, setActiveLocation] = useState();
  const [isVisible,setIsVisible] = useState(false);


  


const handleCliCk = event => {
 const location = locations?.find((i)=>i.short_name===event);
 setCurrentLocation(location);

 const officesInLocation = offices.filter((i)=>i.location === location.id);
 setOfficeInLocation(officesInLocation);

 const headOffice = officesInLocation.find((i)=>i.is_head_office )
 setHeadOffices(headOffice);

 const toggleVisibility = ()=>{
  setIsVisible(!isVisible || isVisible)
}
toggleVisibility();
}

 
  const renderSubOfficesList = () => {
    return (
      <ul className={`list-unstyled d-flex  ${Style.subOfficesList}`}>
        {officeInLocation.map((office, index) => (
          <li
            key={index}
            className={`px-4 py-3 office-item ${Style.activeLocation} ${
              activeLocation === office.place_name
              ? Style.active : ''
            }`}
            onMouseEnter={() => setActiveLocation(office.place_name)}
          >
            <p
              className={`${Style['hov-network']} text-center m-0`}
              style={{whiteSpace: 'nowrap'}}>
              {office.place_name}
            </p>
          </li>
        ))}
      </ul>
    );
  };


  useEffect(() => {
    const getLocationHeaders = async () => {
      try {
        const locationHeaderResponse = await ServiceManager.getLocationHeader();
         if (!locationHeaderResponse?.hasError) {
          const data = locationHeaderResponse?.response?.data;
          setLocationHeader(data );
        } else {
          throw new Error('Error in fetching location header');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getLocationHeaders();
  }, []);


  useEffect(() => {
    const getHomeNetworkData = async () => {
      try {
        const getData = await ServiceManager.getHomeNetworkLocations();
        if (!getData?.hasError) {
          const data = getData?.response?.data;
          if (data) {
            setLocations(data);
            const currentLocation = data[0];
            setCurrentLocation(currentLocation);
            const getNetworkOfficesData = async () => {
              try {
                const getOfficeData = await ServiceManager.getOurNetworkOffices(1);
                if (!getOfficeData?.hasError) {
                  const officeData = getOfficeData?.response?.data;
                  if (officeData) {
                    setOffices(officeData);
                    const officesInCurrentLocation = officeData.filter((item)=>item.location === currentLocation.id);
                    const currentHeadOffice = officesInCurrentLocation.find(i=>i.is_head_office);
                    setHeadOffices(currentHeadOffice);
                    setOfficeInLocation(officesInCurrentLocation); 
                  }
        
                } else {
                  throw new Error('Error in fetching locations data');
                }
              } catch (error) {
                console.log(error);
              }
            };
            getNetworkOfficesData();
          }

        } else {
          throw new Error('Error in fetching locations data');
        }
      } catch (error) {
        console.log(error);
      }
    };
    getHomeNetworkData();
  }, []);


  useEffect(() => {
    
  }, []);



  return (
    <div>
      <ServicesNavbar />
      {!!Object.keys(locationHeader)?.length && (
 
        <TitleComponent
          title={locationHeader?.location_title}
          image={locationHeader?.Location_header_image}
          subTitle={locationHeader?.location_subtitle}
          description={locationHeader?.location_description}
        />
      )}
      <div className={Style['network']}>
        <div className="container container-sm container-md container-lg container-xl container-xxl h-100">
          <div className="row h-100">
            <div
              className={`${Style['map']} col-lg-6 h-100 d-flex justify-content-center align-items-center`}>
              <Network
                toolTip={true}
                onClick={handleCliCk}
                
                />
            </div>
           <div className="col-lg-6 d-flex justify-content-center align-items-center align-items-xl-end flex-column h-100">
              <div className="w-75 text-white">
                <h1 className="">{currentLocation?.place_name}</h1>
                <div className="fw-normal pt-1" style={{lineHeight: '25px',maxWidth:'70%'}} dangerouslySetInnerHTML={{__html:headOffices?.office_address}}>
                 
                </div>
              
                {headOffices?.fax && <p>fax: {headOffices?.fax}</p>}
                
              </div>
              <div className="w-75 text-white">
                <h3 className="fw-bold" style={{lineHeight: '60px'}}>
                  Contact :
                </h3>
                <p className="fw-bold">{headOffices?.country_manager_name}</p>
                <p style={{fontWeight: '500'}}>
                  {headOffices?.designation}
                </p>
                <p className="mb-0">
  Tel No: {headOffices?.country_manager_phone1}
  {headOffices?.country_manager_phone2 ? `, ${headOffices?.country_manager_phone2}` : ''}
</p>
                <p style={{lineHeight: '60px'}}>
                  {headOffices?.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-sm container-md container-lg container-xl container-xxl mt-5">
        <div className="row">
        <div className="col-12">
            <h1 className="text-center" style={{paddingBottom: '15px'}}>
              Offices In {currentLocation?.place_name}
            </h1>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center overflow-auto">
          {renderSubOfficesList()}
          
        </div>
      </div>
      <Services />
      <Industries />
      <Form />
      <Footer />
      <GetAQuote />
    </div>
  );
};

export default OurNetwork;