import {API_ENDPOINTS} from '../utils/urls/index.js';
import {post, get} from './index.js';

export const ServiceManager = {
  postContactForm: async formData => {
    try {
      const response = await post(API_ENDPOINTS.CONTACT_FORM, formData, {
        header: {'Content-Type': `multipart/form-data`},
      });
      return {hasError: false, response};
    } catch (error) {
      console.error(error);
      return {hasError: true, response: error};
    }
  },
  postCareerForm: async formData => {
    try {
      const response = await post(API_ENDPOINTS.CAREER_FORM, formData, {
        header: {'Content-Type': `multipart/form-data`},
      });
      return {hasError: false, response};
    } catch (error) {
      console.error(error);
      return {hasError: true, response: error};
    }
  },
  
  getIndustriesData: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.INDUSTRIES_DATA}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getSpecialServiceHeader: async page => {
    try {
      const response = await get( `${API_ENDPOINTS.SPECIAL_HEADER}?page=${page}` );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getSpecialService: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.SERVICE_SPECIAL}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },
  

  getServicesSliderTitle: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.SERVICES_TITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getServicesSliderData: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.HOME_SERVICES_DATA}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getServiceHeaderData: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.SERVICES_HEADER_LIST}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getSubservicesDataBySlug: async slug => {
    try {
      const response = await get(`${API_ENDPOINTS.SERVICE_DATA_BY_SLUG}${slug}/subservices/`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


  getHomeNetworkTitle: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.HOME_NETWORK_TITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getHomeNetworkLocations: async () => {
    try {
      const response = await get(`${API_ENDPOINTS.HOME_NETWORK_LOCATIONS}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getIndustriesSliderTitle: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.INDUSTRIES_TITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


  getIndustriesSliderData: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.INDUSTRIES_SLIDER}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


   getMarketBlogData: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.BLOGS_DATA}?page=${page}`, );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getMarketBlogDetails: async blodId => {
    try {
      const response = await get(`${API_ENDPOINTS.BLOGS_DATA}${blodId}`, );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getOurStory: async () => {
    try {
      const response = await get(`${API_ENDPOINTS.OUR_STORY}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getMilestoneTitleData: async page =>{
    try {
      const response = await get(`${API_ENDPOINTS.MILESTONETITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getMilestoneData: async page =>{
    try {
      const response = await get(`${API_ENDPOINTS.MILESTONEDATA}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  
  getOurTeamTitle: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.OUR_TEAM_TITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getOurTeam: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.OUR_TEAM}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getOurCertificationsTitle: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.CERTIFICATION_TITLE}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


  getOurCertifications: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.OUR_CERTIFICATES}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getMissionTitle: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.MISSION_VISION_TITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getMission: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.OUR_MISSION_VISION}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getOfficeDetails: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.FOOTER}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getChooseUsDetails: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.CHOOSE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },
 
  getDifferentiatorsTitleData: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.KEY_DIFFERENTIATIONS_TITLE}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getDifferentiatorsData: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.KEY_DIFFERENTIATIONS}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },
  
  getHighlightsTitle: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.HIGHLIGHTS_TITLE}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getHighlightsData: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.HIGHLIGHTS}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getAchievementsTitle: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.ACHIEVEMENTS_TITLE}?page=${page}`,
      );
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getAchievementsData: async page => {
    try {
      const response = await get(
        `${API_ENDPOINTS.ACHIEVEMENT_DATA}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },
  getHomeData: async () => {
    try {
      const response = await get(API_ENDPOINTS.HOME_DATA);
        return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  }, 
 
  getHomeHeaderData: async () => {
    try {
      const response = await get(API_ENDPOINTS.HOME_HEADER_DATA);
        return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getHomeServiceData: async () => {
    try {
      const response = await get(API_ENDPOINTS.SERVICES_HEADER_LIST);
        return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


  getOurNetworkOffices: async page=> {
    try {
      const response = await get( `${API_ENDPOINTS.NETWORK_OFFICES}?page=${page}`);
        return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getAboutHeaderData: async page=> {
    try {
      const response = await get( `${API_ENDPOINTS.ABOUT_HEADER_DATA}?page=${page}`);
        return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },
  getIndustriesHeader: async _ => {
    try {
      const response = await get(`${API_ENDPOINTS.INDUSTRIES_HEADER}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getMarketUpdateHeader: async page => {
    try {
      const response = await get(`${API_ENDPOINTS.MARKET_UPDATES_HEADER}?page=${page}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


  getLocationHeader: async _ => {
    try {
      const response = await get(`${API_ENDPOINTS.LOCATION_HEADER}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },


  getCareerHeader:async() =>{
    try {
      const response = await get(`${API_ENDPOINTS.CAREER_HEADER}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  },

  getContactHeader:async() =>{
    try {
      const response = await get(`${API_ENDPOINTS.CONTACT_HEADER}`);
      return {hasError: false, response};
    } catch (error) {
      return {hasError: true, response: error};
    }
  }
};
