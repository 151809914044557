import React, { useEffect, useState } from 'react';
import '../../css/AboutCss/AboutTeam.scss';
import { ServiceManager } from '../../config/serviceManager';

const AboutTeam = (
  { teamData }
) => {
  const [teamDataRecieved, setTeamDataRecieved] = useState([]);
  const [teamTitle, setTeamTitle] = useState([]);
  // const {title, teamMembers} = teamData;


  useEffect(() => {
    const getTeamTitleData = async () => {
      try {
        const teamResponse = await ServiceManager.getOurTeamTitle(1);
        if (!teamResponse.hasError) {
          const data = teamResponse?.response?.data;
          if (data?.length) {
            setTeamTitle(data[0]);
          }
        } else {
          throw new Error('Error in fetching team data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getTeamTitleData();
  }, []);


  useEffect(() => {
    const getTeamData = async () => {
      try {
        const teamResponse = await ServiceManager.getOurTeam(1);
        if (!teamResponse.hasError) {
          const data = teamResponse?.response?.data;
          if (data) {
            const newResponse = data
            const sortedResponse = newResponse?.sort((a, b) => {
              return a.order_by - b.order_by;
            })
            setTeamDataRecieved(sortedResponse);
          }
        } else {
          throw new Error('Error in fetching team data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getTeamData();
  }, []);

  return (
    <>
      {!!teamDataRecieved?.length && (
        <div className="about-bg-team mb-4 pb-10">
          <div className="container container-sm container-md container-lg container-xl container-xxl ">
            {!!teamTitle && <div className="row">
              <h1 className="about-team-head" dangerouslySetInnerHTML={{ __html: teamTitle.title }}></h1>
            </div>}

            <div className="row text-center team-container-new" style={{ padding: '15px' }}>
              {teamDataRecieved?.map((member, index) => (
                <div
                  className={`col-lg-3 col-md-6 col-sm-12 mb-4 `}
                  key={index}>
                  <div className='our-team-card'>
                    {member?.profile_pic ?   <div className='card-image'>
                      <img
                        src={member?.profile_pic || ''}
                        alt="Team Member"
                        className="team-members-team" />
                    </div> : null}
                  
                    <div className='card-details'>
                      <div dangerouslySetInnerHTML={{ __html: member?.title_name }}></div>
                      <div dangerouslySetInnerHTML={{ __html: member?.designation }}></div>
                    </div>

                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default AboutTeam;
