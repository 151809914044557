import React, { useRef, useState } from "react";
import Modal from "../Modal/Modal";
import Captcha from "../Captcha/index";
import { emailValidator } from "../../utils/helpers";
import { ServiceManager } from "../../config/serviceManager";

const GetAQuoteModal = ({ setShowModal, showModal }) => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    captcha: ""
  });
  const [errors, setErrors] = useState({
		name: '',
		email: '',
		phone: '',
		message: '',
		submission: '',
		captcha: '',
	});

  const handleModalClose = () => {
    setShowModal(false);
    setContactFormData({
      name: "",
      phone: "",
      email: "",
      message: "",
      captcha: ""
    });
    setFormSubmitted(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!contactFormData?.captcha) {
			setErrors({...errors, captcha: 'Please verify captcha', submission: ''});
			return;
		} else if (contactFormData?.phone?.length < 8) {
			setErrors({
				...errors,
				phone: 'Invalid Phone Number',
				submission: '',
			});
			return;
		} else if (!emailValidator(contactFormData?.email)) {
			setErrors({...errors, email: 'Invalid Email', submission: ''});
			return;
		}  else if (!contactFormData?.message) {
			setErrors({
				...errors,
				message: 'Feild must have some values',
				submission: '',
			});
			return;
		} else if (!contactFormData?.name) {
			setErrors({
				...errors,
				name: 'Feild must have some values',
				submission: '',
			});
			return;
		} else {
			setErrors({...errors, submission: ''});
		}
    let data = new FormData();
    for (let key in contactFormData) {
      if(key === 'captcha'){
        continue;
      } 
      data.append(key, contactFormData[key]);
    }
    try {
			const careerFormPostResponse = await ServiceManager.postContactForm(
				data,
			);
			if (careerFormPostResponse?.hasError) {
				throw new Error(careerFormPostResponse?.response);
			}
			setFormSubmitted(true);
		} catch (error) {
			setErrors({...errors, submission: 'Submission failed, please try again'});
			console.error(error);
		}
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
     if (errors?.[name]) {
			setErrors({...errors, [name]: ''});
		}
		if (errors?.submission) {
			setErrors({...errors, submission: ''});
		}
    setContactFormData({
      ...contactFormData,
      [name]: name === "phone" ? value.replace(/\D/g, "") : value,
    });
  };

  const handleCaptchaChange = value => {
		setErrors({...errors, captcha: ''});
		setContactFormData({...contactFormData, captcha: value});
	};

  return (
    <>
      {showModal && (
        <div
          className="position-fixed top-0  w-100 "
          style={{
            height: "100vh",
            left: 0,
            background: "rgba(0,0,0,0.5)",
            zIndex: 111,
          }}
          onClick={() => handleModalClose()}
        ></div>
      )}

      <Modal show={showModal} handleClose={handleModalClose}>
        {!formSubmitted ? (
          <div
            className="container-fluid container-g-q"
            style={{ padding: "25px" }}
          >
            <div className="row">
              <h4
                style={{
                  color: "white",
                  fontWeight: "800",
                }}
              >
                Get A Quote
              </h4>
            </div>
            <form
              className="form-container-g-q"
              onSubmit={handleSubmit}
              ref={form}
              method="post"
            >
              <div className="row">
                {/* Name */}
                <div className="col-lg-6  mb-2">
                  <div className="custom-input-group">
                    <label htmlFor="name" className="form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      className={`${
                        !!errors?.name ? 'input-error' : ''
                        } input-style`}
                      id="name"
                      name="name"
                      value={contactFormData.name}
                      onChange={handleInputChange}
                      placeholder="Enter first and last name"
                      required
                    />
                    {!!errors?.name && (
										  <span className="errorMessage">{errors?.name}</span>
									  )}
                  </div>
                </div>
                {/* Phone Number */}
                <div className="col-lg-6 mb-2">
                  <div className="custom-input-group">
                    <label htmlFor="phone" className="form-label">
                      Phone Number*
                    </label>
                    <input
                      type="text"
                      className={`${
                        !!errors?.phone ? 'input-error' : ''
                        } input-style`}
                      id="phone"
                      name="phone"
                      value={contactFormData.phone}
                      onChange={handleInputChange}
                      placeholder="Eg. 123-456-7890"
                      required
                    />
                    {!!errors?.phone && (
										  <span className="errorMessage">{errors?.phone}</span>
									  )}
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-2">
                  <div className="custom-input-group">
                    <label htmlFor="email" className="form-label-g-q">
                      Email*
                    </label>
                    <input
                      type="email"
                      className={`${
                        !!errors?.email ? 'input-error' : ''
                        } form-control-g-q transparent-input-g-q`}
                      id="email"
                      name="email"
                      value={contactFormData.email}
                      onChange={handleInputChange}
                      placeholder="Eg. youremail@example.com"
                      required
                    />
                    {!!errors?.email && (
										  <span className="errorMessage">{errors?.email}</span>
									  )}
                  </div>
                </div>
              </div>
              {/* Message */}
              <div className="row ">
                <div className="col-lg-12 col-md-12">
                  <div className="custom-input-group mb-2">
                    <label htmlFor="message" className="form-label-g-q mb-2">
                      Message*
                    </label>
                    <textarea
                      className={`${
                        !!errors?.message ? 'input-error' : ''
                        }`}
                      id="message"
                      name="message"
                      rows={5}
                      value={contactFormData.message}
                      onChange={handleInputChange}
                      required
                      placeholder="Your Message"
                    ></textarea>
                    {!!errors?.message && (
                      <span className="errorMessage">{errors?.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-12 col-md-12">
                  <div className="custom-input-group mb-2">
                    <Captcha handleCaptchaChange={handleCaptchaChange} />
                    {!!errors?.captcha && (
                      <span className="errorMessage">{errors?.captcha}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn   get-a-quote-btn">
                    Get A Quote
                  </button>
                  {!!errors?.submission && (
                    <span className="errorMessage">{errors?.submission}</span>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="thank-you-message">
            <h4
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: 800,
                padding: "30px 10px 30px 10px",
              }}
            >
              Thank you for your enquiry!
            </h4>
          </div>
        )}
      </Modal>
    </>
  );
};

export default GetAQuoteModal;
