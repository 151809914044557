import React, { useState, useEffect } from "react";
import "./../../css/Blog.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { ServiceManager } from "../../config/serviceManager";
import { htmlToText } from "../../utils/helpers";
import { MediaUrlBase } from "../../utils/urls";
import "swiper/swiper-bundle.css";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

function Blog() {
  const [blogHeader, setBlogHeader] = useState({});
  const [marketBlog, setMarketBlog] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);

  const cardStyle = {
    width: "100%",
    padding: "10px",
    border: "none",
    borderRadius: "0px",
  };

  useEffect(() => {
    const getMarketHeader = async () => {
      try {
        const marketHeaderResponse = await ServiceManager.getMarketUpdateHeader(
          1
        );
        if (!marketHeaderResponse?.hasError) {
          const data = marketHeaderResponse?.response?.data;
          setBlogHeader(data[0]);
        } else {
          throw new Error("Error in fetching Market header");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMarketHeader();
  }, []);

  useEffect(() => {
    const getMarketUpdatesBlogData = async () => {
      try {
        const blogResponse = await ServiceManager.getMarketBlogData();
        if (!blogResponse.hasError) {
          const data = blogResponse?.response?.data;
          if (data) {
            setMarketBlog(data.reverse());
          }
        } else {
          throw new Error("Error in getting blog data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMarketUpdatesBlogData();
  }, []);

  return (
    <>
      <div className=" blogs-bg-main-bg d-none d-lg-block">
        <div className="col-lg-12">
          <div
            className="home-market-title"
            dangerouslySetInnerHTML={{ __html: blogHeader?.market_title }}
          ></div>
        </div>
        <div className="container container-sm container-md container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center">
            {marketBlog?.slice(0, 4).map((card, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <div className="market-blog-card card p-2 h-100">
                  <img
                    src={`${MediaUrlBase}${card.header_image}`}
                    className="w-100 "
                    style={{
                      height: "250px",
                      borderRadius: "6px",
                      objectFit: "cover",
                    }}
                    alt=""
                    loading="lazy"
                  />
                  <div className="px-2 mt-3 d-flex justify-content-between flex-column h-50">
                    <div className="card-title-wrapper">
                      <div
                        className="card-title fw-bold"
                        style={{ fontSize: "15px" }}
                        dangerouslySetInnerHTML={{
                          __html: card.header_title,
                        }}
                      ></div>
                      <div className="m-0" style={{ fontSize: "13px" }}>
                        {htmlToText(`${card.Highlight?.slice(0, 80)}...`)}
                      </div>
                    </div>
                    <div>
                      <div className="d-flex ">
                        <Link
                          to={`/market-updates/${card.slug}`}
                          style={{
                            color: "rgb(25, 51, 119)",
                          }}
                          className={`d-block py-2 text-decoration-none  fw-semibold`}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <a href="/market-updates" className="view-all-blogs">
              View More
              <div className="ms-3 icon-arrow">
                <i className="bi bi-arrow-right"></i>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="blogs-bg-main-bg d-block d-lg-none">
        <div className="col-lg-6 ">
          <div
            className="home-market-title"
            dangerouslySetInnerHTML={{ __html: blogHeader?.market_title }}
          ></div>
        </div>
        <div className="container">
          <Swiper
            className="h-100"
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            navigation={false}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            onSwiper={(swiper) => setSwiper(swiper)}
            onTouchStart={() => {
              setAutoplay(false);
            }}
            onTouchEnd={() => {
              setAutoplay(true);
            }}
          >
            {marketBlog.map((data, index) => (
              <SwiperSlide key={index}>
                <div className={`col-12`}>
                  <div className={`card p-3`} style={cardStyle}>
                    <img
                      src={`${MediaUrlBase}${data.header_image}`}
                      className="w-100"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0px",
                      }}
                      alt=""
                      loading="lazy"
                    />
                    <div className="px-3 d-flex pt-4 flex-column">
                      <div className="card-title-wrapper">
                        <div
                          className="card-title fw-bold"
                          dangerouslySetInnerHTML={{
                            __html: data.header_title,
                          }}
                        ></div>
                        <div
                          className="m-0 py-4 card-subtitle-wrapper-market"
                          dangerouslySetInnerHTML={{
                            __html: htmlToText(
                              `${data.Highlight?.slice(0, 80)}...`
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="pt-4">
                        <Link
                          to={`/market-updates/${data?.slug}`}
                          style={{
                            color: "rgb(25, 51, 119)",
							fontSize:"33px"
                          }}
                          className="d-block py-2 text-decoration-none  fw-semibold"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="w-100 d-flex justify-content-center align-items-center mt-4">
              <SlidePrevButton />
              <SlideNextButton />
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Blog;
