import React, { useState, useEffect } from "react";
import homeData from "../../homeData.json";
import Services from "../../components/Home/Services";
import Header from "../../components/Home/Header";
import ChooseUs from "../../components/Home/ChooseUs";
import Differentiators from "../../components/Home/Differentiators";
import Achievements from "../../components/Home/Achievements";
import Highlights from "../../components/Home/Highlights";
import Industries from "../../components/Home/Industries";
import Form from "../../components/Home/Form";
import Blog from "../../components/Home/Blog";
import Footer from "../../components/Home/Footer";
import { ServiceManager } from "../../config/serviceManager";
import PageSEO from "../../components/PageSEO";
import Network from "../../components/Home/Network";
// import Network from "../../components/Network";

const Home = () => {
	const [data, setData] = useState();
	const [headerData, setHeaderData] = useState(null);

	useEffect(() => {
		const getHomeHeaderData = async () => {
			try {
				const getHomeHeaderResponse = await ServiceManager.getHomeHeaderData();
				if (!getHomeHeaderResponse.hasError) {
					const data = getHomeHeaderResponse?.response?.data;
					if (data) {
						setHeaderData(data);
					}
				} else {
					throw new Error("Error in getting home header data");
				}
			} catch (error) {
				console.error(error);
			}
		};
		getHomeHeaderData();
	}, []);

	useEffect(() => {
		setData(homeData);
	}, []);

	// if (!data) {
	//   return <div>Loading!!</div>;
	// }


	// useEffect(() => {
	//   // Add Clarity analytics script
	//   const script = document.createElement("script");
	//   script.type = "text/javascript";
	//   script.async = true;
	//   script.src = "https://www.clarity.ms/tag/mcc3yr1ln9";
	//   document.body.appendChild(script);

	//   return () => {
	//     // Clean up script on unmount
	//     document.body.removeChild(script);
	//   };
	// }, []);




	return (
		<>
			<PageSEO api="" />

			{headerData ? <Header headerData={headerData} /> : null}
			{data ? <Services /> : null}
			<Network />
			{data ? <ChooseUs chooseUsData={data.chooseUs} /> : null}
			{data ? (
				<Differentiators differentiatorsData={data.differentiators} />
			) : null}
			{data ? <Achievements achievementsData={data.achievements} /> : null}
			{data ? <Highlights highlightsData={data.highlights} /> : null}
			{data ? <Industries /> : null}
			{data ? <Blog /> : null}
			{data ? <Form /> : null}
			{data ? <Footer /> : null}
		</>
	);
};

export default Home;
