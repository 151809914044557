const BaseUrl = `https://pixelboho.developers.li/api`;
export const MediaUrlBase = `https://pixelboho.developers.li`;
export const MediaUrl = `https://pixelboho.developers.li/cms_backend/`;
export const API_ENDPOINTS = {
	CONTACT_FORM: `${BaseUrl}/contact-form/`,
	CAREER_FORM: `${BaseUrl}/career-submissions/`,
	ACHIEVEMENTS_TITLE :`${BaseUrl}/achievementssection/`,
	INDUSTRIES_SLIDER: `${BaseUrl}/industry-cards/`,
	SERVICES_TITLE : `${BaseUrl}/service-title/`,
	HOME_SERVICES_DATA: `${BaseUrl}/services_cards/`,
	HOME_NETWORK_TITLE : `${BaseUrl}/ournetwork_title/`,
	HOME_NETWORK_LOCATIONS : `${BaseUrl}/locations/`,
	NETWORK_OFFICES:`${BaseUrl}/offices/`,
	INDUSTRIES_TITLE: `${BaseUrl}/industry_titles/`,
	INDUSTRIES_DATA: `${BaseUrl}/industries/edit/`,
	// MARKET_TITLE : '/markets/',
	MARKET_UPDATES_HEADER: `${BaseUrl}/header/markets/`,
	BLOGS_DATA : `${BaseUrl}/blogposts/`,
	OUR_STORY: `${BaseUrl}/our-story/`,
	MILESTONETITLE:`${BaseUrl}/milestonetitle/`,
	MILESTONEDATA:`${BaseUrl}/milestones/`,
	OUR_TEAM_TITLE:`${BaseUrl}/our-team-title/`,
	OUR_TEAM: `${BaseUrl}/our-team/`,
	CERTIFICATION_TITLE : `${BaseUrl}/certificationtitle/`,
	OUR_CERTIFICATES: `${BaseUrl}/certification/`,
	MISSION_VISION_TITLE:`${BaseUrl}/what-we-are-title/`,
	OUR_MISSION_VISION: `${BaseUrl}/what-we-are/`,
	FOOTER: `${BaseUrl}/footer/`,
	CHOOSE: `${BaseUrl}/chooses/`,
	KEY_DIFFERENTIATIONS_TITLE: `${BaseUrl}/key-diffrentiatestitle/`,
	KEY_DIFFERENTIATIONS: `${BaseUrl}/key-diffrentiates/`,
	HIGHLIGHTS_TITLE: `${BaseUrl}/highlightstitle/`,
	HIGHLIGHTS: `${BaseUrl}/highlights/`,
	ACHIEVEMENT_DATA: `${BaseUrl}/achievements/`,
	HOME_HEADER_DATA: `${BaseUrl}/home/header/`,
	ABOUT_HEADER_DATA: `${BaseUrl}/header/about-page-sections/`,
	CAREER_HEADER: `${BaseUrl}/header/career-page/`,
	CONTACT_HEADER: `${BaseUrl}/contact/header/`,
	INDUSTRIES_HEADER: `${BaseUrl}/header/industries/`,
	LOCATION_HEADER: `${BaseUrl}/header/locationpage/`,
	SERVICES_HEADER_LIST: `${BaseUrl}/header/services/`,
	SERVICE_DATA_BY_SLUG: `${BaseUrl}/services/`,
	SPECIAL_HEADER :`${BaseUrl}/header/specialized-subservice/`,
	SERVICE_SPECIAL: `${BaseUrl}/specialized-service/`
};

