import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ServiceManager } from "../../config/serviceManager";
import { MediaUrlBase } from "../../utils/urls";
import "../../css/Services.css";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const Services = () => {
  const [autoplay, setAutoplay] = useState(true);
  const [servicesData, setServicesData] = useState([]);
  const [serviceTitle, setServiceTitle] = useState([]);

  useEffect(() => {
    const getServicesTitleData = async () => {
      try {
        const serviceResponse = await ServiceManager.getServicesSliderTitle(1);
        if (!serviceResponse?.hasError) {
          const data = serviceResponse?.response?.data;
          if (data) {
            setServiceTitle(data);
          }
        } else {
          throw new Error(serviceResponse?.response);
        }
      } catch (error) {
        console.error({ error });
      }
    };
    getServicesTitleData();
  }, []);

  useEffect(() => {
    const getServicesData = async () => {
      try {
        const serviceResponse = await ServiceManager.getServicesSliderData(1);
        if (!serviceResponse?.hasError) {
          const data = serviceResponse?.response?.data;
          if (data?.length) {
            const newResponse = data;
            const sortedResponse = newResponse?.sort((a, b) => {
              return a.order_by - b.order_by;
            });
            setServicesData(sortedResponse);
          }
        } else {
          throw new Error(serviceResponse?.response);
        }
      } catch (error) {
        console.error({ error });
      }
    };
    getServicesData();
  }, []);

  return (
    !!servicesData?.length && (
      <>
        <div
          className="service-container-main container-fluid py-3"
          style={{ marginTop: "40px" }}
        >
          {!!serviceTitle && (
            <div
              className="car-service-cards-heading text-center"
              dangerouslySetInnerHTML={{ __html: serviceTitle[0]?.title }}
            ></div>
          )}
          <div className="car-service-cards">
            <div className="service-swiper-wrapper">
              <Swiper
                spaceBetween={0}
                slidesPerView={"auto"}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                loop={true}
                navigation={false}
                modules={[Autoplay]}
                breakpoints={{
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },

                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                }}
                onSlideChange={() => setAutoplay(true)}
                className="services-slider"
              >
                {servicesData?.map(
                  ({ title, icon, description, link }, index) => (
                    <SwiperSlide key={`${title}_index`}>
                      <Link to={link} style={{ textDecoration: "none" }}>
                        <div className="card card-med">
                          <div className="d-flex justify-content-center align-items-center w-100 h-50">
                            <div>
                            <img
                              src={`${MediaUrlBase}${icon}`}
                              className="card-img-top service-icon"
                              alt={title}
                              loading="lazy"
                            />
                          </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center flex-column" style={{position:"relative"}}>
                            <div className="" style={{
                              position:"absolute", top:"-15px"
                            }}>
                            <h2>{title}</h2>
                            <div
                              style={{
                                wordBreak: "normal",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              dangerouslySetInnerHTML={{ __html: description }}
                            ></div>
                              </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  )
                )}

                {servicesData?.length >= 2 && (
                  <div className="arrows-service">
                    <div className="col-lg-12">
                      <div className="col-lg d-flex justify-content-center">
                        <SlidePrevButton />
                        <SlideNextButton />
                      </div>
                    </div>
                  </div>
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Services;
