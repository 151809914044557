import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import OtherServices from "../../components/Services/OtherServices";
import Industries from "../../components/Home/Industries";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import { Card, Col } from "react-bootstrap";
import "./service.scss";
import GetAQuote from "../../components/GetAQuote";
import { ServiceManager } from "../../config/serviceManager";
import {  MediaUrlBase } from "../../utils/urls";



// const getServiceType = (type) => {
// 	switch (type) {
// 		case "customs_clearance":
// 			return "Customs Clearance";
// 		case "road_freight":
// 			return "Road Freight";
// 		case "ocean_freight":
// 			return "Ocean Freight";
// 		case "air_freight":
// 			return "Air Freight";
// 		case "project_cargo_services":
// 			return "Project Cargo Services";
// 		case "vessel_agency":
// 			return "Vessel Agency";
// 		case "warehousing_and_distribution":
// 			return "Warehousing and Distribution";
// 		case "specialized_services":
// 			return "Specialized Services";
// 		case "ship_spare_logistics":
// 			return "Ship Spare Logistics";
// 		case "event_logistics":
// 			return "Event Logistics";
// 		case "civil_defense_approved_transportation":
// 			return "Civil Defense Approved Transportation";
// 		default:
// 			return "Customs Clearance";
// 	}
// };

// const getComponents = (type, props) => {
//   switch (type) {
//     case "tile":
//       return <CardTile {...props} cardsPerRow={3} cardCount={4} />;
//     case "imageCards":
//       return <ImageCard {...props} />;
//     default:
//       return "";
//   }
// };

function Service() {


	let { slug } = useParams();
	const [servicedetail, setServicedetail] = useState([])
	const [sliderdetail, setSliderdetail] = useState({})

	// const [recievedServiceHeaderData, setRecievedServiceHeaderData] = useState();

	useEffect(() => {
		const getServiceHeadersData = async () => {
			try {
				const serviceHeaderResponse = await ServiceManager.getSubservicesDataBySlug(slug);
				if (!serviceHeaderResponse?.hasError) {
					const data = serviceHeaderResponse?.response?.data;
					// console.log("====>gdgdg", data)
					if (data) {
						setServicedetail(data?.subheadings)
						setSliderdetail(data?.service)
					}
				} else {
					throw new Error(serviceHeaderResponse?.response)
				}
			} catch (error) {
				console.error({ error });
			}
		};
		getServiceHeadersData();
	}, [slug]);


	// const { type, dedicated } = useParams();
	// const serviceType = getServiceType(
	// 	dedicated ? (dedicated !== "" ? dedicated : type) : type
	// );
	// const { header, introduction } = data[serviceType];
	// const cardsKeys = Object.keys(data[serviceType]).filter((key) =>
	// 	key.includes("cards")
	// );
	// const getProps = (type) => {
	// 	switch (type) {
	// 		case "tile":
	// 			return { cards: cardsKeys.map((key) => data[serviceType][key]) };
	// 		case "imageCards":
	// 			return { cards: data[serviceType].cards };
	// 		default:
	// 			return "";
	// 	}
	// };

	return (
		<>

			<ServicesNavbar />

			<div className="container-fluid blog-spl-nav-bg blog-spl-quote-img" style={{ backgroundImage: `url(${MediaUrlBase}${sliderdetail?.image})` }}>
				<div className="col-lg-12 col-sm-12 col-md-12">
					<div className="row">
						<div className="col-lg-12 col-sm-12 col-md-12 blog-spl-quote">
							<h1 className="blog-spl-text text-center">{sliderdetail?.title}</h1>
						</div>
					</div>
				</div>
			</div>

			<div className="container container-sm container-md container-lg container-xl container-xxl "><div className="row" ><div className="col-lg-12 py-3" ><div className="row"  >
				<p className="text-nav" dangerouslySetInnerHTML={{__html:sliderdetail?.description}}></p>
			</div>
			</div>
			</div></div>

			{/* <p>{JSON.stringify(servicedetail)}</p> */}
			<div className={`specialised-service`}>

				{servicedetail?.map((item, indx) => (
					<div key={indx} className="bg-container">
						<div className="container container-sm container-md container-lg container-xl container-xxl mt-5">
							<h1 {...item.heading} dangerouslySetInnerHTML={{ __html: item?.subheading }}></h1>
						</div>


						<div
							
							className={`cardtile_section position-relative`}
							style={{
								height:
									window.screen.width > 1200
										? item.subservices.length === 1 && "700px"
										: "auto",
							}}
						>
							<div className="container container -sm container-md container-lg container-xl container-xxl h-100">
								<div className={`row `}>
									{item?.subservices?.map(({ image, sub_title, title, ...rest }, index) => (
										<Col
											sm={6}
											md={4}
											lg={3}
											key={index}
											{...rest}
											className="mb-4 mb-lg-0  d-flex justify-content-center align-items-center pb-3 "
										>
											<Card
												className={`single-card w-100 p-3`}
												style={{ maxWidth: "400px",height:"100%" }}
											>
												{image && (
													<Card.Img
														src={`${MediaUrlBase}${image}`}
														alt={`${sub_title} - ${index + 1}`}
														className="cc-type-serv-general"
													/>
												)}
												<Card.Body>
													<Card.Title style={{ fontSize: '18px' }}>{sub_title}</Card.Title>
													<Card.Text></Card.Text>
												</Card.Body>
											</Card>
										</Col>
									))}
								</div>
								{/* ))} */}
							</div>
						</div>


					</div>
				))}

				{/* {getComponents(
            data[serviceType].type,
            getProps(data[serviceType].type)
          )} */}
			</div>

			<OtherServices title={'Other Services'} />
			{/* <Services /> */}

			<Industries />
			<Form />
			<Footer />
			<GetAQuote />
		</>
	);
}

export default Service;





