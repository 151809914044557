import React, { useEffect, useState } from "react";
import styles from "../../css/AboutCss/AboutSimply.module.scss";
import { ServiceManager } from "../../config/serviceManager";
import { MediaUrlBase } from "../../utils/urls";

const AboutSimply = () => {
  const [missionDataReceived, setMissionDataReceived] = useState([]);
  const [missionTitle,setMissionTitle] = useState([]);

 
  useEffect(() => {
    const getMissionTitle = async () => {
      try {
        const missionTitleResponse = await ServiceManager.getMissionTitle(1);
         if (!missionTitleResponse?.hasError) {
          const data = missionTitleResponse?.response?.data;
          if (data?.length) {
            setMissionTitle(data);
          } 
        } else {
          throw new Error("Error in fetching mission title data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMissionTitle();
  }, []);


  useEffect(() => {
    const getMissionData = async () => {
      try {
        const missionResponse = await ServiceManager.getMission(1);
         if (!missionResponse?.hasError) {
          const data = missionResponse?.response?.data;
          if (data?.length) {
            setMissionDataReceived(data);
          } 
        } else {
          throw new Error("Error in fetching mission data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMissionData();
  }, []);

  return (
    <>
      {!!missionDataReceived?.length && (
        <div className="container container-sm container-md container-lg container-xl container-xxl pb-5">
          <div className="row" style={{ paddingBottom: "40px" }}>
          {!!missionTitle && <div className="display-4 about-simply-head-main text-center" dangerouslySetInnerHTML={{__html:missionTitle[0]?.title}}></div>}
          </div>
          <div className="row">
            {missionDataReceived?.map((item, index) => (
              <div className="col-lg-4 mb-4 mb-lg-0" key={index}>
                <div className={`py-2 px-4 ${styles.simplyAboutBg}`}>
                  <img
                    src={`${MediaUrlBase}${item?.icon}`}
                    className={` ${styles.simplyAboutBgOne}`}
                    alt={item?.alt_img_text}
                    loading="lazy"
                  />
                  <div
                    className={`${styles.simplyAboutHeading}`}
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                  ></div>
                  <div
                    className={`${styles.simplyAboutParag}`}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AboutSimply;
