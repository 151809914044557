import React, {useState} from 'react';
import '../../css/ContactCss/ContactUsForm.scss';
import Captcha from '../Captcha';
import {emailValidator} from '../../utils/helpers';
import {ServiceManager} from '../../config/serviceManager';

const ContactUsForm = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [contactFormData, setContactFormData] = useState({
		name: '',
		phonenumber: '',
		message: '',
		email: '',
		captcha: '',
	});
	const [errors, setErrors] = useState({
		name: '',
		email: '',
		phonenumber: '',
		message: '',
		submission: '',
		captcha: '',
	});

	const handleChange = event => {
		const {name, value} = event?.target;
		if (errors?.[name]) {
			setErrors({...errors, [name]: ''});
		}
		if (errors?.submission) {
			setErrors({...errors, submission: ''});
		}
		setContactFormData({
			...contactFormData,
			[name]: name === 'phonenumber' ? value.replace(/\D/g, '') : value,
		});
	};

	const handleSubmit = async event => {
		event?.preventDefault(); // Prevents the default form submission behavior
		if (!contactFormData?.captcha) {
			setErrors({...errors, captcha: 'Please verify captcha', submission: ''});
			return;
		} else if (!emailValidator(contactFormData?.email)) {
			setErrors({...errors, email: 'Invalid Email', submission: ''});
			return;
		} else if (contactFormData?.phonenumber?.length < 8) {
			setErrors({
				...errors,
				phonenumber: 'Invalid Phone Number',
				submission: '',
			});
			return;
		} else if (!contactFormData?.message) {
			setErrors({
				...errors,
				message: 'Feild must have some values',
				submission: '',
			});
			return;
		} else if (!contactFormData?.name) {
			setErrors({
				...errors,
				name: 'Feild must have some values',
				submission: '',
			});
			return;
		} else {
			setErrors({...errors, submission: ''});
		}
		const formData = new FormData();

		formData.append('name', contactFormData?.name || '');
		formData.append('phone', contactFormData?.phonenumber || '');
		formData.append('message', contactFormData?.message || '');
		formData.append('email', contactFormData?.email || '');
		try {
			const contactFormPostResponse = await ServiceManager.postContactForm(
				formData,
			);
			if (contactFormPostResponse?.hasError) {
				throw new Error(contactFormPostResponse?.response);
			}
			setIsSubmitted(true);
		} catch (error) {
			setErrors({...errors, submission: 'Submission failed, please try again'});
			console.error(error);
		}
	};

	const handleReset = () => {
		setIsSubmitted(false);
		setContactFormData({
			name: '',
			phonenumber: '',
			message: '',
			email: '',
			captcha: '',
		});
	};

	const handleCaptchaChange = value => {
		setErrors({...errors, captcha: ''});
		setContactFormData({...contactFormData, captcha: value});
	};

	return (
		<div className="split-form-container ">
			<div className="container container-sm container-md container-lg container-xl container-xxl ">
				<div className="row">
					<div className="col-lg-6 career-left d-flex justify-content-center align-items-center">
						<h1 className="career-left-text ">Let's Connect And Talk </h1>
					</div>
					<div className="col-lg-6 contact-us-split-form">
						{isSubmitted ? (
							<div
								className="thank-you-message-main-form"
								style={{borderRadius: '5%'}}>
								<h3
									className="thank-you-message-main-form-one ZoomIn"
									style={{
										color: '#fff',
										fontWeight: '800',
										borderRadius: '10px',
									}}>
									Thank you for submitting your enquiry!
								</h3>

								<div className="text-center">
									<button
										type="button"
										className="btn get-a-quote-btn my-4"
										onClick={handleReset}>
										Close
									</button>
								</div>
							</div>
						) : (
							<form onSubmit={handleSubmit}>
								<div className="mb-3">
									<label htmlFor="name" className="contact-us-form-label">
										Name*
									</label>
									<input
										type="text"
										className={`${
											!!errors?.name ? 'input-error' : ''
										} contact-us-form-control contact-us-transparent-input`}
										id="name"
										name="name"
										value={contactFormData.name}
										onChange={handleChange}
										placeholder="Enter first and last name"
										required
									/>
									{!!errors?.name && (
										<span className="errorMessage">{errors?.name}</span>
									)}
								</div>
								<div className="mb-3">
									<label htmlFor="email" className="contact-us-form-label">
										Email*
									</label>
									<input
										type="email"
										className={`${
											!!errors?.email ? 'input-error' : ''
										} contact-us-form-control contact-us-transparent-input`}
										id="email"
										name="email"
										value={contactFormData.email}
										onChange={handleChange}
										placeholder="Eg. youremail@email.com"
										required
									/>
									{!!errors?.email && (
										<span className="errorMessage">{errors?.email}</span>
									)}
								</div>
								<div className="mb-3">
									<label
										htmlFor="phonenumber"
										className="contact-us-form-label">
										Phone Number*
									</label>
									<input
										type="text"
										className={`${
											!!errors?.phonenumber ? 'input-error' : ''
										} contact-us-form-control contact-us-transparent-input `}
										id="phonenumber"
										name="phonenumber"
										value={contactFormData.phonenumber}
										onChange={handleChange}
										placeholder="Enter 10-digit mobile number"
										required
									/>
									{!!errors?.phonenumber && (
										<span className="errorMessage">{errors?.phonenumber}</span>
									)}
								</div>
								<div className="mb-3">
									<label htmlFor="message" className="contact-us-form-label">
										Message*
									</label>
									<textarea
										className={`${
											!!errors?.message ? 'input-error' : ''
										} contact-us-form-control contact-us-transparent-input`}
										id="message"
										value={contactFormData.message}
										onChange={handleChange}
										name="message"
										required
										placeholder="Your Message"></textarea>
									{!!errors?.message && (
										<span className="errorMessage">{errors?.message}</span>
									)}
								</div>
								<div className="my-3">
									<Captcha handleCaptchaChange={handleCaptchaChange} />
									{!!errors?.captcha && (
										<span className="errorMessage">{errors?.captcha}</span>
									)}
								</div>
								<button
									type="submit"
									className="contact-us-btn btn-primary contact-us-btn-custom">
									Submit
								</button>
								{!!errors?.submission && (
									<span className="errorMessage">{errors?.submission}</span>
								)}
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUsForm;
