import React, { useEffect, useState } from 'react'
import { ServiceManager } from '../../config/serviceManager';
import GetAQuote from '../GetAQuote';
import TitleComponent from '../titleComponent';
import IndustriesOil from './IndustriesOil';

function IndustriesPage() {
	const [industriesHeader, setIndustriesHeader] = useState([]);

	useEffect(() => {
		const getIndustriesHeaders = async () => {
			try {
				const industriesHeaderResponse = await ServiceManager.getIndustriesHeader();
				if (!industriesHeaderResponse?.hasError) {
					const data = industriesHeaderResponse?.response?.data;
					setIndustriesHeader(data);
				} else {
					throw new Error('Error in fetching career header');
				}
			} catch (error) {
				console.error(error);
			}
		};
		getIndustriesHeaders();
	}, []);

	return (
		<>
			{industriesHeader?.map((header, i) => (
				<TitleComponent key={i}
					title={header?.industry_title}
					image={header?.industry_image}
					description={header?.industry_description}
				/>
			))}
			<IndustriesOil />
			<GetAQuote />
		</>
	)
}

export default IndustriesPage;