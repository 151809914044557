import React, { useState, useEffect } from "react";
import "../../css/AboutCss/AboutMilestones.css";
import slideBluePartner from "../../images/AboutCelebs/timeline-blue-partner.webp";
import slideWhitePartner from "../../images/AboutCelebs/timeline-white-partner.webp";
import pin from "../../images/AboutCelebs/pin.webp";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { ServiceManager } from "../../config/serviceManager";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const AboutMilestones = () => {
  const [milestoneTitle, setMilestoneTitle] = useState({});
  const [milestoneData, setMilestoneData] = useState([]);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const getMilestoneTitle = async () => {
      try {
        const getTitleData = await ServiceManager.getMilestoneTitleData(1);
        if (!getTitleData?.hasError) {
          const data = getTitleData?.response?.data;
          if (data) {
            setMilestoneTitle(data[0]);
          }
        } else {
          throw new Error('Error in fetching milestone title data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getMilestoneTitle();
  }, []);

  useEffect(() => {
    const fetchMilestoneData = async () => {
      try {
        const milestoneResponse = await ServiceManager.getMilestoneData(1);
        if (!milestoneResponse.hasError) {
          const data = milestoneResponse?.response?.data;
          if (Array.isArray(data)) {
            setMilestoneData(data);
          } else {
            throw new Error('Expected milestone data to be an array');
          }
        } else {
          throw new Error('Error in getting milestone data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchMilestoneData();
  }, []);

  let milestones = Array.isArray(milestoneData) ? [...milestoneData, { title: "" }, ...milestoneData] : [];

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);

  return (
    <>
      {/* for larger screens */}
      <div className="container container-sm container-md container-lg container-xl container-xxl">
        <div className="row milestone-heading">
          {!!milestoneTitle && (
            <div className="col-lg-12 mt-5">
              <div className="milestones-head" dangerouslySetInnerHTML={{ __html: milestoneTitle?.title }}></div>
              <div className="milestones-parag" dangerouslySetInnerHTML={{ __html: milestoneTitle?.sub_title }}></div>
            </div>
          )}
        </div>
      </div>

      <div className="about-slider-container d-flex align-items-center d-none d-lg-flex">
        <div
          className="about-slider d-grid justify-content-center align-items-center"
          style={{ gridTemplateColumns: "repeat(10,257px 110px)" }}
        >
          {milestones.map((milestone, index) => (
            <div key={index}
              className="position-relative d-flex justify-content-center align-items-center"
              style={{
                width: "100%",
                height: "294px",
              }}
            >
              <div className="position-relative w-100 h-100">
                <img
                  src={index % 2 === 0 ? slideWhitePartner : slideBluePartner}
                  alt=""
                  style={{
                    width: index % 2 === 0 ? "100%" : "111%",
                    height: index % 2 === 0 ? "130px" : "34px",
                    position: "absolute",
                    top: index % 2 === 0 ? "50% " : "calc(50% - 48px)",
                    left: index % 2 === 0 ? 0 : "-5px",
                    transform: "translateY(-50%)",
                  }}
                  loading="lazy"
                />
                <img
                  src={index % 2 === 0 ? slideWhitePartner : slideBluePartner}
                  alt=""
                  style={{
                    width: index % 2 === 0 ? "100%" : "111%",
                    height: index % 2 === 0 ? "131px" : "34px",
                    position: "absolute",
                    top: index % 2 === 0 ? "50% " : "calc(50% - 48px)",
                    left: index % 2 === 0 ? 0 : "-5px",
                    transform: "translateY(-50%)",
                  }}
                  loading="lazy"
                />
                {milestone?.title !== "" && (
                  <img
                    src={pin}
                    alt=""
                    style={{
                      height: "196px",
                      position: "absolute",
                      top: index % 2 === 0 ? "99%" : "2px",
                      left: "50%",
                      transform:
                        index % 2 !== 0
                          ? "translate(-50%, -50%)"
                          : "translate(-50% ,-50%) rotate(180deg)",
                    }}
                    loading="lazy"
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    top: index % 2 !== 0 ? "-115px" : "unset",
                    bottom: index % 2 !== 0 ? "unset" : "-100px",
                    left: index % 2 !== 0 ? "45%" : "64%",
                    transform:
                      index % 2 === 0
                        ? `translate( -50%, 100%)`
                        : "translate(-30%, -87%)",
                  }}
                >
                  <div
                    className="p-0 "
                    style={{
                      width: "300px",
                    }}
                  >
                    <div className="achievements-para" dangerouslySetInnerHTML={{ __html: milestone?.achievements }}></div>
                  </div>
                </div>
              </div>

              {/* for alsi logo */}
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "196px",
                  position: "absolute",
                  height: "185px",
                  backgroundImage:
                    milestone.title !== "" &&
                    `url(${
                      index % 2 !== 0
                        ? "images/Vector-2.webp"
                        : "images/Vector-1.webp"
                    })`,
                  backgroundSize: "196px",
                  backgroundRepeat: "no-repeat",
                  top:
                    index % 2 === 0 ? "calc(50% - 50px)" : "calc(50% + 63px)",
                  left: index % 2 === 0 ? "50%" : "calc(50% + 1px)",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div
                  className={`d-flex justify-content-center align-items-center flex-column  ${
                    index % 2 !== 0 ? "text-white" : ""
                  }`}
                  style={{ whiteSpace: "break-spaces" }}
                >
                  <h3>{milestone?.year}</h3>
                  <div className="flex items-center justify-center" style={{ fontSize: '13px' }}>
                    <div dangerouslySetInnerHTML={{ __html: milestone?.title }}></div>
                  </div>
                </div>
              </div>

              {milestone?.title === "" && (
                <img
                  src="images/nav_logo.webp"
                  alt=""
                  style={{
                    width: "125px",
                    height: "165px",
                    position: "absolute",
                    top: "-10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  loading="lazy"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* for smaller screens */}
      <div className="container">
        <div
          className="row d-flex d-lg-none pb-5 pt-2"
          style={{ overflowX: "auto" }}
        >
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            navigation={false}
            className="mySwiper"
            onSwiper={setSwiper}
          >
            {milestones.map((milestone, index) => (
              <SwiperSlide key={index}>
                <div className="position-relative">
                  <img
                    src={index % 2 === 0 ? slideWhitePartner : slideBluePartner}
                    alt=""
                    style={{
                      width: index % 2 === 0 ? "100%" : "111%",
                      height: index % 2 === 0 ? "130px" : "34px",
                      position: "absolute",
                      top: index % 2 === 0 ? "50%" : "calc(50% - 48px)",
                      left: index % 2 === 0 ? 0 : "-5px",
                      transform: "translateY(-50%)",
                    }}
                    loading="lazy"
                  />
                  {milestone?.title !== "" && (
                    <img
                      src={pin}
                      alt=""
                      style={{
                        height: "196px",
                        position: "absolute",
                        top: index % 2 === 0 ? "99%" : "2px",
                        left: "50%",
                        transform:
                          index % 2 !== 0
                            ? "translate(-50%, -50%)"
                            : "translate(-50% ,-50%) rotate(180deg)",
                      }}
                      loading="lazy"
                    />
                  )}
                  <div
                    className="p-0"
                    style={{
                      width: "300px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <div
                      className="achievements-para"
                      dangerouslySetInnerHTML={{ __html: milestone?.achievements }}
                    ></div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <SlidePrevButton />
            <SlideNextButton />
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default AboutMilestones;
