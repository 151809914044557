import React, { Fragment, useEffect, useState } from 'react'
import { ServiceManager } from '../../config/serviceManager';
import GetAQuote from '../GetAQuote';
import TitleComponent from '../titleComponent';
import LTRCard from '../ImageCards/LTRCard/LTRCard';
import loader from "../../images/lottieAnimation/loader.json";
import Lottie from 'lottie-react';
import Industries from '../Home/Industries';
import OtherServices from './OtherServices';
import { MediaUrlBase } from '../../utils/urls';

function SpecialServicePage() {
	const [loading, setLoading] = useState(true);
	const[serviceHeader,setServiceHeader] = useState([]);
	const [specialservice, setSpecialservice] = useState([]);


	
	useEffect(() => {
		const getSpecialisedServiceHeader = async () => {
		  try {
			const responseHeaderData = await ServiceManager.getSpecialServiceHeader(1);
 			if (!responseHeaderData.hasError) {
			  const data = responseHeaderData?.response?.data;
			  if(data){
				setServiceHeader(data[0])
			  }
					   
			}else {
			  throw new Error("Error in getting specialized services header data");
			}
		  } catch (error) {
			console.error(error);
		  }
		};
		getSpecialisedServiceHeader();
	  }, []);


	useEffect(() => {
		const getSpecialisedService = async () => {
			try {
				setLoading(true);
				const responseData = await ServiceManager.getSpecialService(1);
				if (!responseData?.hasError) {
					const data = responseData?.response?.data;
					if (data?.length) {
						setSpecialservice(data);
					}

				} else {
					throw new Error(responseData?.response);
				}
			} catch (error) {
				console.log({ error });
			} finally {
				setLoading(false);
			}
		};
		getSpecialisedService();
	}, []);



	return (
		<>
			{!!serviceHeader && <TitleComponent
				title={serviceHeader?.header_title}
				image={serviceHeader?.header_image}
			/>}
			<div className="my-4 industries">
				{loading ? (
					<>
						<div className="lottieContainer">
							<Lottie
								animationData={loader}
								loop={true}
								style={{ width: '300px', height: '300px' }}
							/>
						</div>
					</>
				) : (
					<div className="container container-sm container-md container-lg container-xl container-xxl ">
						{specialservice.map((data) => {
							return (
								<Fragment key={data?.id}>
									<LTRCard
										title={data?.title}
										description={[data?.description]}
										image={`${MediaUrlBase}${data?.image}`}
										alt_value={data?.alt_img_text}
										link={data?.link}
									/>
								</Fragment>
							);
						})}
					</div>
				)}
			</div>
			<OtherServices title={'Other Services'} />
			<Industries />
			<GetAQuote />
		</>
	)
}

export default SpecialServicePage;