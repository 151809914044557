import React, { useRef, useState } from "react";
import "./../../css/CareersFormCss/CareersForm.css";
import Captcha from "../Captcha/index";
import { emailValidator } from "../../utils/helpers";
import { ServiceManager } from "../../config/serviceManager";


const CareersForm = () => {
  const form = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [careerFormData, setCareerFormData] = useState({
    name: "",
    phone: "",
    message: "",
    email: "",
    resume: "",
    captcha: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    submission: "",
    resume: "",
    captcha: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (errors?.[name]) {
      setErrors({ ...errors, [name]: "" });
    }
    if (errors?.submission) {
      setErrors({ ...errors, submission: "" });
    }
    setCareerFormData({
      ...careerFormData,
      [name]: name === "phone" ? value.replace(/\D/g, "") : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!careerFormData?.captcha) {
      setErrors({ ...errors, captcha: "Please verify captcha", submission: "" });
      return;
    } else if (!emailValidator(careerFormData?.email)) {
      setErrors({ ...errors, email: "Invalid Email", submission: "" });
      return;
    } else if (careerFormData?.phone?.length < 8) {
      setErrors({
        ...errors,
        phone: "Invalid Phone Number",
        submission: "",
      });
      return;
    } else if (!careerFormData?.message) {
      setErrors({
        ...errors,
        message: "Field must have some values",
        submission: "",
      });
      return;
    } else if (!careerFormData?.name) {
      setErrors({
        ...errors,
        name: "Field must have some values",
        submission: "",
      });
      return;
    } else if (!careerFormData?.resume) {
      setErrors({
        ...errors,
        resume: "Please upload a file within 1MB",
        submission: "",
      });
      return;
    } else {
      setErrors({ ...errors, submission: "" });
    }
    let data = new FormData();
    for (let key in careerFormData) {
      if (key === "captcha") {
        continue;
      }
      data.append(key, careerFormData[key]);
    }
    try {
      const careerFormPostResponse = await ServiceManager.postCareerForm(data);
      if (careerFormPostResponse?.hasError) {
        throw new Error(careerFormPostResponse?.response);
      }
      setIsSubmitted(true);
    } catch (error) {
      setErrors({
        ...errors,
        submission: "Submission failed, please try again",
              });
      console.error(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files?.[0] || "";
    if (file && file.size > 1 * 1024 * 1024) {
      setErrors({
        ...errors,
        resume: "File size must be less than 1MB",
      });
    } else {
      setErrors({ ...errors, resume: "" });
      setCareerFormData({
        ...careerFormData,
        resume: file,
      });
    }
  };

  const handleReset = () => {
    setIsSubmitted(false);
    setCareerFormData({
      name: "",
      phone: "",
      message: "",
      email: "",
      resume: "",
      captcha: "",
    });
  };

  const handleCaptchaChange = (value) => {
    setErrors({ ...errors, captcha: "" });
    setCareerFormData({ ...careerFormData, captcha: value });
  };

  return (
    <>
      <div className="container-fluid split-form-container">
        <div className="container container-sm container-md container-lg container-xl container-xxl">
          <div className="row">
            <div className="col-lg-6 career-left d-flex justify-content-center align-items-center">
              <h1 className="career-left-text ">Be a part of ALSI</h1>
            </div>
            <div className="col-lg-6 contact-us-split-form">
              {isSubmitted ? (
                <div
                  className="thank-you-message-main-form"
                  style={{ borderRadius: "5%" }}
                >
                  <h3
                    className="thank-you-message-main-form-one ZoomIn"
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                      paddingTop: "50px",
                      borderRadius: "10px",
                    }}
                  >
                    Thank you for submitting your enquiry!
                  </h3>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn get-a-quote-btn my-4"
                      onClick={handleReset}
                    >
                      Close
                    </button>
                  </div>
                </div>
              ) : (
                <form ref={form} onSubmit={handleSubmit} method="post">
                  <div className="mb-3">
                    <label htmlFor="name" className="contact-us-form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      className={`${
                        !!errors?.name ? "input-error" : ""
                      } contact-us-form-control contact-us-transparent-input`}
                      id="name"
                      name="name"
                      value={careerFormData.name}
                      onChange={handleChange}
                      placeholder="Enter first and last name"
                      required
                    />
                    {!!errors?.name && (
                      <span className="errorMessage">{errors?.name}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="contact-us-form-label">
                      Email*
                    </label>

                    <input
                      type="email"
                      className={`${
                        !!errors?.email ? "input-error" : ""
                      } contact-us-form-control contact-us-transparent-input`}
                      id="email"
                      name="email"
                      value={careerFormData.email}
                      onChange={handleChange}
                      placeholder="Eg. youremail@email.com"
                      required
                    />
                    {!!errors?.email && (
                      <span className="errorMessage">{errors?.email}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="contact-us-form-label">
                      Phone Number*
                    </label>

                    <input
                      type="text"
                      className={`${
                        !!errors?.phone ? "input-error" : ""
                      } contact-us-form-control contact-us-transparent-input`}
                      id="phone"
                      name="phone"
                      value={careerFormData.phone}
                      onChange={handleChange}
                      placeholder="Enter 10-digit mobile number"
                      required
                    />
                    {!!errors?.phone && (
                      <span className="errorMessage">{errors?.phone}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="contact-us-form-label">
                      Message*
                    </label>

                    <textarea
                      className={`${
                        !!errors?.message ? "input-error" : ""
                      } contact-us-form-control contact-us-transparent-input`}
                      id="message"
                      value={careerFormData.message}
                      onChange={handleChange}
                      name="message"
                      required
                      placeholder="Your Message"
                    ></textarea>
                    {!!errors?.message && (
                      <span className="errorMessage">{errors?.message}</span>
                    )}
                  </div>
                  <div className="input-group mb-3 relative row">
                    <input
                      type="file"
                      name="resume"
                      className="form-control"
                      id="resume"
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.pdf,.doc,.docx"
                    />
                    {!!errors?.resume && (
                      <span className="errorMessage">{errors?.resume}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <Captcha handleCaptchaChange={handleCaptchaChange} />
                    {!!errors?.captcha && (
                      <span className="errorMessage">{errors?.captcha}</span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="contact-us-btn btn-primary contact-us-btn-custom"
                  >
                    Submit
                  </button>
                  {!!errors?.submission && (
                    <span className="errorMessage">{errors?.submission}</span>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersForm;
