import React, {useState, useEffect} from 'react';
import '../../css/Highlights.css';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import {ServiceManager} from '../../config/serviceManager';
import { MediaUrlBase } from '../../utils/urls';

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const Highlights = ({highlightsData}) => {
  // const {title, description, highlights} = highlightsData;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [highlightTitle,setHighlightTitle] = useState({});
   const [highlightsDataRecieved, setHighlightsDataRecieved] = useState([]);

 
  const handleCardClick = index => {
    setCurrentIndex(index);
  };

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);



  useEffect(() => {
    const getHighlightTitle = async () => {
      try {
        const getHighlightsTitleResponse = await ServiceManager.getHighlightsTitle(1);
          if (!getHighlightsTitleResponse?.hasError) {
          const data = getHighlightsTitleResponse?.response?.data;
           if (data) {
            setHighlightTitle(data);
          } 
        } else {
          throw new Error('Error in fetching highlights data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getHighlightTitle();
  }, []);




  useEffect(() => {
    const getHighlightData = async () => {
      try {
        const getHighlightsResponse = await ServiceManager.getHighlightsData(1);
         if (!getHighlightsResponse?.hasError) {
          const data = getHighlightsResponse?.response?.data;
          if (data?.length) {
            setHighlightsDataRecieved(data);
          } 
        } else {
          throw new Error('Error in fetching highlights data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getHighlightData();
  }, []);

  return (
    !!highlightsDataRecieved?.length && (
      <>
        <div
          className="highlights-container-bg d-none d-lg-block"
          id="justify-swipper">
          <div className="row justify-content-end">
           {highlightTitle &&
            <div className="col read-icon-a">
              <div 
                className="high-txt-stl" 
                dangerouslySetInnerHTML={{ __html: highlightTitle[0]?.title }}
              />
          </div>
           }
            {highlightsDataRecieved?.map((highlights, index) => (
              <div
                className={`swiper-slide-higlight ${
                  index === currentIndex ? 'active' : ''
                }`}
                key={index}>
                <div className="highlight-item">
                  <img
                    src={`${MediaUrlBase}${highlights?.icon}`}
                    className="multi-pad pb-4"
                    style={{width: '55px'}}
                    alt="Highlight"
                    loading="lazy"
                  />
                  <h5
                    className="fw-bold"
                    dangerouslySetInnerHTML={{__html: highlights?.sub_title}}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container-fluid highlights-bg-second d-lg-none">
          <div className="row highlights-second-head">
           {!!highlightTitle && <div className="col-lg-12 highlights-second-main">
              <div style={{ color: 'white', textAlign: 'center' }} dangerouslySetInnerHTML={{__html:highlightTitle[0]?.title}}></div>
            </div>}
          </div>

          <div className="container"> 
            <div className="row highlights-f-mode d-flex d-lg-none">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: true,
                }}
                loop={true}
                navigation={false}
                className="h-100"
                breakpoints={{
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                }}
                onSwiper={swiper => setSwiper(swiper)}
                onTouchStart={() => {
                  if (swiper && swiper.autoplay && swiper.autoplay.running) {
                    swiper.autoplay.stop();
                  }
                }}
                onTouchEnd={() => {
                  if (swiper && swiper.autoplay && !swiper.autoplay.running) {
                    swiper.autoplay.start();
                  }
                }}>
                {highlightsDataRecieved?.map((highlights, index) => (
                  <SwiperSlide key={index}>
                    <div className="highlights-f-mode marquee">
                      <div
                        className={`col-lg d-flex align-items-center justify-content-center card-wrap-second ${
                          index === currentIndex ? ' active' : ''
                        }`}
                        key={index}
                        onClick={() => handleCardClick(index)}>
                        <div
                          className={`highlight-item-second ${
                            index === currentIndex ? ' active' : ''
                          }`}>
                          <img
                            src={`${MediaUrlBase}${highlights?.icon}`}
                            className="multi-pad-second pb-4"
                            style={{width: '55px'}}
                            alt="Highlight"
                            loading="lazy"
                          />
                          <h2
                            className="fw-bold pb-2"
                            dangerouslySetInnerHTML={{__html: highlights?.sub_title}}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <div className="w-100 d-flex justify-content-center align-items-center">
                  <SlidePrevButton />
                  <SlideNextButton />
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Highlights;
