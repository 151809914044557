import React, {useEffect} from 'react';
import styles from '../../css/AboutCss/AboutCertified.module.scss';
import {ServiceManager} from '../../config/serviceManager';
import {useState} from 'react';
import { MediaUrlBase } from '../../utils/urls';

const AboutCertified = () => {
  const [certificationDataRecieved, setCertificationDataRecieved] = useState([]);
   const [certificationTitle, setCertificationTitle] = useState([]);

 
  useEffect(() => {
    const getCertificationTitle = async () => {
      try {
        const certificationResponse = await ServiceManager.getOurCertificationsTitle(1);
         if (!certificationResponse?.hasError) {
          const data = certificationResponse?.response?.data;
          if (data?.length) {
            setCertificationTitle(data[0]);
          } 
        } else {
          throw new Error('Error in fetching certification data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCertificationTitle();
  }, []);


  useEffect(() => {
    const getCertificationData = async () => {
      try {
        const certificationResponse = await ServiceManager.getOurCertifications(1);
        if (!certificationResponse?.hasError) {
          const data = certificationResponse?.response?.data;
          if (data?.length) {
            setCertificationDataRecieved(data);
          } 
        } else {
          throw new Error('Error in fetching certification data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCertificationData();
  }, []);



  return (
    !!certificationDataRecieved?.length && (
      <div className="container container-sm container-md container-lg container-xl container-xxl">
        <div className="row">
         <div className="col-lg-12">
         {!!certificationTitle && (<h1 className={styles.AboutCertifiedHOne} dangerouslySetInnerHTML={{__html:certificationTitle.title}}>
                         </h1>)}
          </div>
        </div>

        <div style={{overflowX: 'auto'}} className="row certified-about-top ">
          {certificationDataRecieved?.map(
            (certification, index) =>
              !!certification?.certificate_image && (
                <div className="col-lg" key={index}>
                  <div className={`${styles.certifiedBox}`}>
                    <div className={` ${styles.cerifiedImgRow}`}>
                      <img className={styles.certificationImage}
                        src={`${MediaUrlBase}${certification?.certificate_image}`}
                        alt={certification?.alt_img_text}
                      />
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    )
  );
};

export default AboutCertified;


