import React, {useEffect, useState} from 'react';
import ServicesNavbar from '../../components/Services/ServicesNavbar';
import CareersForm from '../../components/CareersForm/CareersForm';
import Footer from '../../components/Home/Footer';
import GetAQuote from '../../components/GetAQuote';
import TitleComponent from '../../components/titleComponent';
import {ServiceManager} from '../../config/serviceManager';

const headerTestData = {
  title: 'Careers',
  header_image: '/images/Careers/CareerImgBanner.webp',
  description: '',
};
const Careers = () => {
  const [careerHeader, setCareerHeader] = useState({});
  
  useEffect(() => {
    const getCareerHeaders = async () => {
      try {
        const careerHeaderResponse = await ServiceManager.getCareerHeader();
        if (!careerHeaderResponse?.hasError) {
          const data = careerHeaderResponse?.response?.data;
          setCareerHeader(data || headerTestData);
        } else {
          throw new Error('Error in fetching career header');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCareerHeaders();
  }, []);
  return (
    <>
      <ServicesNavbar />
      {!!Object.keys(careerHeader)?.length && (
        <TitleComponent
          title={careerHeader?.title}
          image={careerHeader?.header_image}
          description={careerHeader?.description}
        />
      )}
      <CareersForm />
      <Footer />
      <GetAQuote />
    </>
  );
};

export default Careers;
