import React from "react";
import Style from "./pagemain.module.scss";
const PageMain = ({
  main = {},
  title,
  introduction,
  mainRender = () => {},
}) => {
  return (
    <>
      <div className={`${Style["page-main-container"]}`} style={main.style}>
        <div className="row h-100 w-100 mx-auto">
          <div className="col-lg-12 h-100 d-flex justify-content-center align-items-center">
            <h1 className={`${Style["page-main-title"]} `}>{title}</h1>
          </div>
        </div>
        {mainRender()}
      </div>

{/*     
        <div className="container container-sm container-md container-lg container-xl container-xxl ">
          <div className="row">
            <div className="col-lg-12 py-5">
             
                <div className="row py-3" >
                  <p dangerouslySetInnerHTML={{__html:introduction}}></p>
                </div>
              
            </div>
          </div>
        </div> */}
    
    </>
  );
};

export default PageMain;
