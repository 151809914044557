import React, {useState, useEffect} from 'react';
import '../../css/Differentiators.css';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {ServiceManager} from '../../config/serviceManager';
import { MediaUrlBase } from '../../utils/urls';

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none me-4 service-button"
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={() => swiper.slideNext()}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const Differentiators = ({differentiatorsData}) => {
   const [swiper, setSwiper] = useState(null);
  const [autoplay, setAutoplay] = useState(true);
  const [differentiatorsDataRecieved, setDifferentiatorsDataRecieved] = useState([]);
   const[title,setTitle] = useState([]);
 

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);



  useEffect(() => {
    const getDifferentiatorsTitle = async () => {
      try {
        const getDiffData = await ServiceManager.getDifferentiatorsTitleData(1);
         if (!getDiffData?.hasError) {
          const data = getDiffData?.response?.data;
          if (data) {
            setTitle(data[0]);
          } 
        } else {
          throw new Error('Error in fetching differentiators Title data');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getDifferentiatorsTitle();
  }, []);



  useEffect(() => {
    const getDifferentiatorsData = async () => {
      try {
        const getDiffData = await ServiceManager.getDifferentiatorsData(1);
         if (!getDiffData?.hasError) {
          const data = getDiffData?.response?.data;
          if (data?.length) {
            const newResponse = data
            const sortedResponse = newResponse?.sort((a, b) => {
              return a.order_by - b.order_by;
            })
            setDifferentiatorsDataRecieved(sortedResponse);
          }
        } else {
          throw new Error('Error in fetching differentiators data');
        }
      } catch (error) {
        setDifferentiatorsDataRecieved(differentiatorsData?.items); //[To do]
        console.error(error);
      }
    };
    getDifferentiatorsData();
  }, []);

  return (
    !!differentiatorsDataRecieved?.length && (
      <div
        className="container diffs-containers"
        >
      {!!title && (<div className="row text-center">
        <p className="feature-text pt-4"></p>
        <div className="differentiators-header" dangerouslySetInnerHTML={{__html:title?.title}}></div>
        <div className="differentiators-description pb-5" dangerouslySetInnerHTML={{__html:title?.subtitle}}></div>
      </div>)}

        {/* Map over the array and create rows with a maximum of 5 items per row */}
        {[...Array(Math.ceil(differentiatorsDataRecieved?.length / 5))].map(
          (_, rowIndex) => (
            <div className="row differentiators-row-one d-none d-lg-flex" key={rowIndex}>
              {differentiatorsDataRecieved
                .slice(rowIndex * 5, (rowIndex + 1) * 5)
                .map((differentiators, index) => (
                  <div className="col-lg text-center" key={index}>
                    <div className="diff-img-setup">
                      <div className="diffs-one">
                        <img
                          src={`${MediaUrlBase}${differentiators?.icon}`}
                          className="differentiators-img"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="diff-sem-parag"  dangerouslySetInnerHTML={{__html:differentiators?.tagline}}>
                              </div>
                  </div>
                ))}
            </div>
          ),
        )}

        <div className="container">
          <div className="row differentiators-row d-flex d-lg-none">
            <Swiper
              className="h-100"
              spaceBetween={0}
              slidesPerView={1}
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
              }}
              loop={true}
              navigation={false}
              breakpoints={{
                360: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              onSwiper={swiper => setSwiper(swiper)}
              onTouchStart={() => {
                setAutoplay(false);
              }}
              onTouchEnd={() => {
                setAutoplay(true);
              }}>
              {differentiatorsDataRecieved?.map((differentiator, index) => (
                <SwiperSlide key={differentiator.id}>
                  <div
                    className="diff-img-setup d-flex justify-content-center align-items-center flex-column"
                    style={{width: '100%'}}>
                    <div className="diffs-one">
                      <img className='differentiators-img' src={`${MediaUrlBase}${differentiator?.icon}`} alt="" loading="lazy" />
                    </div>
                    <div className="diff-sem-parag text-center mt-3"  dangerouslySetInnerHTML={{__html:differentiator?.tagline}}></div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="w-100 d-flex justify-content-center align-items-center">
                <SlidePrevButton />
                <SlideNextButton />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    )
  );
};

export default Differentiators;
