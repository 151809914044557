import React, { useState, useRef, useEffect } from "react";
import Style from "./blog.module.scss";
import { Link } from "react-router-dom";
import GetAQuote from "../../components/GetAQuote";
import { htmlToText } from "../../utils/helpers";
import { MediaUrlBase } from "../../utils/urls";

const Blog = ({ blogPageData, singleblog }) => {
 	const [isOpen, setIsOpen] = useState(false);
	const recentPostsRef = useRef(null);
	const blogContainerRef = useRef(null);

	useEffect(() => {
		const recentPostsObserver = new IntersectionObserver(
			(entries) => {
				const entry = entries[0];
				if (!entry.isIntersecting) {
					blogContainerRef.current.classList.add("scrolling");
				} else {
					blogContainerRef.current.classList.remove("scrolling");
				}
			},
			{
				threshold: 0.8,
			}
		);

		recentPostsObserver.observe(recentPostsRef.current);

		return () => {
			recentPostsObserver.disconnect();
		};
	}, []);

	return (
		<>
			<div className="container container-sm container-md container-lg container-xl container-xxl ">

				<div className="col-lg-12 my-4">
					<div className={`${Style["introduction"]}`}>{singleblog?.quotes}</div>
				</div>

				<div className="row">
					<div className="col-lg-6 " ref={blogContainerRef}>
						<div  dangerouslySetInnerHTML={{ __html: singleblog?.Highlight }}></div>
					</div>
					<div className="col-lg-6">
						<div
							className="sticky"
							style={{
								backgroundColor: "rgb(239, 239, 239)",
								padding: "30px",
								position: "sticky",
								top: 145,
							}}
						>
							<h5 style={{ fontWeight: "800" }}>Recent Posts</h5>
							<div ref={recentPostsRef}>
								{blogPageData?.slice(0,3)?.map((blogData, recentPostIndex) => (
									<div className="mt-3" key={recentPostIndex}>
										<img
											src={`${MediaUrlBase}${blogData?.header_image}`}
											className="w-100"
											style={{ width: "100%", height: "22vh" }}
											alt={`Recent Post - ${recentPostIndex + 1}`}
											loading="lazy"
										/>
										<h6 className="my-3" style={{ fontWeight: "800" }}>
											{blogData?.header_title}
										</h6>
										<div style={{ fontSize: "14px" }}>{htmlToText(singleblog?.Highlight)?.slice(0,300)}...</div>
										<Link
											to={`/market-updates/${blogData?.slug}`}
											className="recent-read"
											style={{ textDecoration: "none", padding: 0 }}
										>
											Read More
										</Link>
									</div>
								)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>)
			<GetAQuote showModal={isOpen} setShowModal={setIsOpen} />
		</>
	);
};

export default Blog;
