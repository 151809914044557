import { MediaUrlBase } from '../../utils/urls';
import styles from './titleComponent.module.scss';

const TitleComponent = ({ image, title, description, subTitle }) => {
	return (
		<>
			<div
				className={styles.titleMainContainer}
				style={{ backgroundImage: ` linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)),url(${MediaUrlBase}${image})` }}>
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<div className={styles.titleText}>
						<div dangerouslySetInnerHTML={{ __html: title }} />
					</div>
				</div>
			</div>
			{title === "Our Network" || !!subTitle && (
				<div className="w-100 p-4">
					<div dangerouslySetInnerHTML={{ __html: subTitle }}></div>
					<div dangerouslySetInnerHTML={{ __html: description }}></div>
				</div>
			)}
		</>
	);
};

export default TitleComponent;


