import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import "./../../css/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faXTwitter

} from "@fortawesome/free-brands-svg-icons";
import { ServiceManager } from "../../config/serviceManager";

const burgerLine = "4px";
const burgerColor = "#2044A2";
const sidemenuBackground = "linear-gradient(to top, #193377, #009ADB)";
const sidemenuLink = "#fff";
const sidemenuFooter = "#2044A2";

const Burger = styled.button`
  background: none;
  border: none;
  outline: none;
  z-index: 6;
  
  @media (max-width: 768px) {
    top: 1rem;
    right: 3rem;
  }

  @media (max-width: 360px) {
    top: 1rem;
    right: 2rem;
    width: 43px;
    height: 43px;
  }

  span {
    position: relative;
    height: ${burgerLine};
    width: 40px;
    background: ${burgerColor};
    display: inline-block;
    transition: all 0.5s ease;

    @media (max-width: 1024px) {
      height: 12px;
      width: 100px;
      margin: 0px 40px;
    }

    @media (max-width: 768px) {
      height: 12px;
      width: 100px; 
    }

    &:before,
    &:after {
      content: "";
      height: ${burgerLine};
      width: 100%;
      background: ${burgerColor};
      position: absolute;
      border-radius: 2px;
      transition: all 0.3s ease-in-out;

      @media (max-width: 1024px) {
      height: 10px;
      margin: -13px 0px;
    }
    }

    &:before {
      top: -10px;
      right: 0;
    }
    &:after {
      bottom: -10px;
      right: 0;
    }
  }

  &.open span {
    background: transparent;
    &:before {
      opacity: 1;
      transform: rotate(41deg);
      width: 50%;
      top: -31px;
      background: #ffffff;
      left: 60px;
      z-index: 2;
      position: absolute;
      @media (max-width: 768px) {
        left: 15px;
        top: -28px;
      }

      @media (max-width: 360px) {
        left: 15px;
        top: -28px;
      }
    }

    &:after {
      opacity: 1;
      transform: rotate(-40deg);
      width: 50%;
      bottom: 20px;
      background: #fff;
      left: 60px;
      @media (max-width: 768px) {
        left: 15px;
        top: -20px;
      }

      @media (max-width: 360px) {
        left: 15px;
        top: -20px;
      }
    }
  }
`;

const Sidemenu = styled.div`
  background: ${sidemenuBackground};
  top: 0;
  position: fixed;
  right: 0;
  border-radius: 0;
  transform: translateX(100%);
  transition: all 0.5s cubic-bezier(0.04, 0.79, 0.34, 1.3);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  width: 100%; /* Full width for mobile devices */
  height: 100%;

  /* Adjustments for desktop and tablet */
  @media (min-width: 1024px) {
    /* Assuming 1024px as the breakpoint for desktop/tablet */
    width: 22%; /* Width of 22% for desktop and tablet */
    height: 100%;
  }

  /* Adjustments for smaller tablets and iPads */
  @media (min-width: 350px) and (max-width: 780px) {
    width: 20%; /* Width of 20% for smaller tablets and iPads */
    height: 100%;
  }

  ul {
    padding: 0;
    width: 75%;
    height: 100%;
    overflow: auto;
    li {
      display: block;
      transition: all 0.5s cubic-bezier(0.04, 0.79, 0.34, 1.3);

      a,
      p {
        color: ${sidemenuLink};
        font-weight: 200;
        text-decoration: none;
        transition: 0.3s all ease-in-out;
        &:hover {
          font-weight: bold;
          color: ${sidemenuLink};
        }
      }
    }
  }

  ul.submenu {
    width: 100%;
    padding-left: 15px;
    margin: 0;
  }

  .menu li:not(:last-child),
  .menu.submenu div:not(:last-child) {
    border-bottom: 1px solid #ffffff47;
  }

  .sidemenu_footer {
    background: ${sidemenuFooter};
    color: #2044a2;
    padding: 1rem;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.04, 0.79, 0.34, 1.3);
  }

  &.open {
    transform: translateX(0);
    z-index: 100;
  }
`;

const NavbarContainer = styled.nav`
  background: transparent;
  max-width: 95%;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 460px) {
    max-width: 100%;
  }
`;

const LogoImage = styled.img`
  width: 70px;
  height: auto;
  z-index: 0;

    @media (max-width: 1024px) {
  width: 150px;
  height: auto;
  margin: 20px
    }
`;

const Navbar = ({ menuOpen, setMenuOpen = () => {}, toggleMenu }) => {
  const location = useLocation();
  const [offset, setOffset] = useState(0);
  const [services, setServices] = useState([]);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [globalNetworkDropdownOpen, setGlobalNetworkDropdownOpen] =
    useState(false);

  const socialIconStyle = {
    display: "flex",
    zIndex: "1",
    paddingBottom: "10px",
  };

  const socialIconsStyle = {
    display: "flex",
    alignItems: "center",
    marginRight: "70px",
  };

  const iconStyle = {
    marginRight: "15px",
    color: "#707070",
    fontSize: "15px",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    }
  };

  const iconStyleSidebar = {
    color: "#ffffff",
    fontSize: "14px",
    marginRight: "16px",
    "@media (max-width: 768px)": {
      fontSize: "10px",
    },

    "@media (max-width: 375px)": {
      fontSize: "10px",
    },
  };

  useEffect(() => {
    const getSubServices = async () => {
      try {
        const getHomeHeaderResponse = await ServiceManager.getHomeServiceData();
        if (!getHomeHeaderResponse.hasError) {
          const data = getHomeHeaderResponse?.response?.data;
          if (data) {
            setServices(data);
          }
        } else {
          throw new Error("Error in getting home header data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getSubServices();
  }, []);

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.scrollY);
      if (menuOpen) toggleMenu();
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    // return () => window.removeEventListener("scroll", onScroll);
  }, [offset]);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(`.home-navbar`);
      const socialButtonDiv = document.querySelector(`.social-navbar`);
      if (navbar) {
        const scrollThreshold = 550;

        // navbar.style.transition = "all 0.5s ease";
        navbar.classList.toggle("sticky", window.scrollY > scrollThreshold);

        socialButtonDiv.style.transition = "all 0.5s ease";
        socialButtonDiv.classList.toggle(
          "d-none",
          window.scrollY > scrollThreshold
        );
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
      setMenuOpen(false);
    }
  }, [location, setMenuOpen]);

  return (
    <div className="home-navbar">
      <div
        className="container-fluid container-fluid-sm container-fluid-md container-fluid-lg container-fluid-xl container-fluid-xxl "
        style={{ width: "93%" }}
      >
        <div className="row">
          <ul className="nav justify-content-end mt-3 navbar-pos px-4 social-navbar">
            <li className="nav-item" style={socialIconStyle}>
              <Link
                to="https://www.facebook.com/alsimarineservice"
                className="nav-link-navbar-main"
                target="_blank"
              >
                <div className="social-icons" style={iconStyle}>
                  <FontAwesomeIcon icon={faFacebook} />
                </div>
              </Link>

              <Link
                to="https://www.instagram.com/alsiformarineservices_official/"
                className="nav-link-navbar-main"
                target="_blank"
              >
                <div className="social-icons" style={iconStyle}>
                  <FontAwesomeIcon icon={faInstagram} />
                </div>
              </Link>

              <Link
                to="https://www.linkedin.com/company/alsi-official/"
                className="nav-link-navbar-main"
                target="_blank"
              >
                <div className="social-icons" style={iconStyle}>
                  <FontAwesomeIcon icon={faLinkedin} />
                </div>
              </Link>

              <Link
                to="https://twitter.com/ALSI_OFFICIAL"
                className="nav-link-navbar-main"
                target="_blank"
              >
                <div className="social-icons" style={iconStyle}>
                <FontAwesomeIcon icon={faXTwitter} />
                </div>
              </Link>

              <Link
                to="https://in.pinterest.com/alsiformarineservicellc/"
                className="nav-link-navbar-main"
                target="_blank"
              >
                <div
                  className="social-icons"
                  style={{ ...iconStyle, marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faPinterest} />
                </div>
              </Link>
            </li>
          </ul>

          <NavbarContainer className="navbar ">
            <div className="w-100 px-4 px-lg-0 d-flex justify-content-between align-items-center">
              <Link to="/" className="navbar-brand">
                <LogoImage src="/images/nav_logo.webp" alt="Alsi Global Logo" />
              </Link>

              <Burger className={menuOpen ? "open" : ""} onClick={toggleMenu}>
                <span></span>
              </Burger>
            </div>
          </NavbarContainer>

          {/* Side Menu */}
          {menuOpen && (
            <div
              onScrollCapture={() => {}}
              className="position-fixed top-0  w-100 "
              style={{
                height: "100vh",
                left: 0,
                background: "rgba(0,0,0,0.5)",
                zIndex: 100,
              }}
              onClick={toggleMenu}
            ></div>
          )}
          <Sidemenu className={menuOpen ? "open main_menu sidebar-container" : ""}>
            <div className="w-100 d-flex justify-content-end toggle-close-btn">
              <button
                type="button"
                className="btn btn-right-arrow text-white"
                // style={{ fontSize: "26px" }}
                onClick={toggleMenu}
              >
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>
            <ul className="menu sidebar-links">
              <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                <Link
                  to="/"
                  className={`${
                    window.location.pathname === "/" ? "fw-bold" : ""
                  }`}
                >
                  Home
                </Link>
              </li>
              <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                <Link
                  to="/about_us"
                  className={`${
                    window.location.pathname === "/about_us" ? "fw-bold" : ""
                  }`}
                >
                  About Us
                </Link>
              </li>

              <li
                onClick={() => setServicesDropdownOpen(!servicesDropdownOpen)}
              >
                <p
                  className={`mb-0 ${
                    window.location.pathname.split("/")[1] === "services"
                      ? "fw-bold"
                      : ""
                  }`}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  Services
                  <i
                    className="bi bi-chevron-down"
                    style={{
                      color: "white",
                      paddingLeft: "5px",
                      border: "none",
                      fontWeight: "800",
                    }}
                  ></i>
                </p>
                {servicesDropdownOpen && services?.length ? (
                  <ul className="submenu">
                    {services?.map((item, index) =>
                      item?.is_specialized ? null : (
                        <div key={index}>
                          <Link
                            to={`/services/${item?.slug}`}
                            className={`${
                              window.location.pathname ===
                              `/services/${item?.slug}`
                                ? "fw-bold"
                                : ""
                            }`}
                            style={{ 
                                color: "white",
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "start",
                                // lineHeight: "0",
                                fontSize: "14px",
                                padding:"6px",
                                justifyContent:"left"
                             }}
                          >
                            {item?.title}
                          </Link>
                        </div>
                      )
                    )}
                    <div>
                      <Link
                        to="/specialized_services"
                        className={`${
                          window.location.pathname.split("/")[2] ===
                          "specialized_services"
                            ? "fw-bold"
                            : ""
                        }`}
                        style={{ fontSize: "14px",
                          display: "flex",
                          alignItems: "start",
                           justifyContent:"start",
                           position:"relative",
                           left:"6px"
                         }}
                      >
                        Specialized Services
                      </Link>
                    </div>
                  </ul>
                ) : null}
              </li>

              <li
                style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}
                onClick={() =>
                  setGlobalNetworkDropdownOpen(!globalNetworkDropdownOpen)
                }
              >
                <Link
                  to="/our_network"
                  className={`${
                    window.location.pathname === "/our_network" ? "fw-bold" : ""
                  }`}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Our Network
                </Link>
              </li>

              <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                <Link
                  to="/industries"
                  className={`${
                    window.location.pathname === "/industries" ? "fw-bold" : ""
                  }`}
                >
                  Industries
                </Link>
              </li>
              <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                <Link
                  to="/market-updates"
                  className={`${
                    window.location.pathname === "/market-updates"
                      ? "fw-bold"
                      : ""
                  }`}
                >
                  Market Updates
                </Link>
              </li>
              <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                <Link
                  to="/contact_us"
                  className={`${
                    window.location.pathname === "/contact_us" ? "fw-bold" : ""
                  }`}
                >
                  Contact Us
                </Link>
              </li>
              <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                <Link
                  to="/careers"
                  className={`${
                    window.location.pathname === "/careers" ? "fw-bold" : ""
                  }`}
                >
                  Careers
                </Link>
              </li>
            </ul>
            <div className="w-100 d-flex justify-content-center sidemenu-icon-set">
              <ul className="w-75 m-0">
                <li className="nav-item" style={socialIconsStyle}>
                  <div className="social-icon" style={iconStyleSidebar}>
                    <Link
                      className="nav-link-navbar-main"
                      target="_blank"
                      to="https://www.facebook.com/alsimarineservice"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </Link>
                  </div>

                  <div className="social-icon" style={iconStyleSidebar}>
                    <Link
                      className="nav-link-navbar-main"
                      target="_blank"
                      to="https://www.instagram.com/alsiformarineservices_official/"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </Link>
                  </div>

                  <div className="social-icon" style={iconStyleSidebar}>
                    <Link
                      className="nav-link-navbar-main"
                      target="_blank"
                      to="https://www.linkedin.com/company/alsi-official/"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </Link>
                  </div>

                  <div className="social-icon">
                    <Link
                      className="nav-link-navbar-main d-flex align-items-center justify-content-center me-2"
                      target="_blank"
                      to="https://twitter.com/ALSI_OFFICIAL"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        height="20px"
                        fill="white"
                        viewBox="0 0 16 16"
                        className="responsive-svg"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                      </svg>
                    </Link>
                  </div>

                  <div className="social-icon" style={iconStyleSidebar}>
                    <Link
                      className="nav-link-navbar-main"
                      target="_blank"
                      to="https://in.pinterest.com/alsiformarineservicellc/"
                    >
                      <FontAwesomeIcon icon={faPinterest} />
                    </Link>
                  </div>
                </li>

                <li style={{ opacity: servicesDropdownOpen ? "0.5" : "1" }}>
                  <span className="mt-2 mb-0 rights-recieved-p">
                    © ALSI Global 2023, All Rights Reserved.
                  </span>
                </li>
              </ul>
            </div>
          </Sidemenu>
        </div>
      </div>
      <button
        type="button"
        className={`btn floating-top-btn
      
        `}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
      >
        <i className="bi bi-arrow-up"></i>
      </button>
    </div>
  );
};

export default Navbar;
