import React, { useRef, useState } from "react";
import "./../../css/Form.scss";
import Captcha from "../Captcha/index";
import { emailValidator } from "../../utils/helpers";
import { ServiceManager } from "../../config/serviceManager";

const Form = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [contactFormData, setContactFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    captcha: "",
  });
  const [errors, setErrors] =  useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    captcha: "",
  })

  const handleCaptchaChange = value => {
    setErrors({...errors, captcha: ''});
    setContactFormData({...contactFormData, captcha: value});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if(errors?.[name]){
      setErrors({...errors, [name]: ''});
    }
    if(errors?.submission){
      setErrors({...errors, submission: ''});
    }

    setContactFormData((prevData) => ({
      ...prevData,
      [name]: name === "phone" ? value.replace(/\D/g, "") : value,
    }));
  };

  const handleReset = () => {
    setIsSubmitted(false);
    setContactFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
      captcha:""
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!contactFormData?.captcha){
      setErrors({...errors, captcha: 'Please verify captcha', submission: ''});
      return;
    } else if (!emailValidator(contactFormData?.email)) {
      setErrors({...errors, email: 'Invalid Email', submission: ''});
      return;
    } else if (contactFormData?.phone?.length < 8) {
      setErrors({
        ...errors,
        phone: 'Invalid Phone Number',
        submission: '',
      });
      return;
    } else if (!contactFormData?.message) {
      setErrors({
        ...errors,
        message: 'Feild must have some values',
        submission: '',
      });
      return;
    } else if(!contactFormData?.name){
      setErrors({
        ...errors,
        name: 'Feild must have some values',
        submission: '',
      });
      return;
    } else {
      setErrors({...errors, submission: ''});
    }
    let data = new FormData();
    for (let key in contactFormData) {
      if(key === 'captcha'){
        continue;
      } 
      data.append(key, contactFormData[key]);
    }
    try {
      const contactFormResponse = await ServiceManager.postContactForm(data);
      if(!contactFormResponse.hasError){
        setIsSubmitted(true);
      }
    } catch(error){
      setErrors({...errors, submission: 'Submission failed, please try again'});
      console.error(error);
    }
  };

  return (
    <div className="container-fluid split-form-container pt-0">
      <div className="split-form-image"></div>
      {isSubmitted ? (
        <div className="thank-you-message-main-form split-form">
          <h3
            className="thank-you-message-main-form-one ZoomIn"
            style={{ color: "#fff", fontWeight: "800", paddingTop: "50px" }}
          >
            Thank you for submitting your enquiry!
          </h3>
          <div className="text-center">
            <button
              type="button"
              className="btn get-a-quote-btn my-4"
              onClick={handleReset}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div className="split-form" style={{ padding: "35px" }}>
          <div className="form-container">
            <div className="w-100">
              <h2>How can</h2>
              <h4 className="text-white sub-title">We Help You?</h4>
            </div>
            <form ref={form} onSubmit={handleSubmit} method="post">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name*
                </label>
                <input
                  type="text"
                  className={` ${
                    !!errors?.name ? 'input-error' : ''
                  } form-control transparent-input`}
                  id="name"
                  name="name"
                  value={contactFormData.name}
                  onChange={handleChange}
                  style={{ color: "white" }}
                  placeholder="Enter first and last name"
                  required
                />
                {!!errors?.name && (
                  <span className="errorMessage">{errors?.name}</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email*
                </label>
                <input
                  type="email"
                  className={`${
                    !!errors?.email ? 'input-error' : ''
                  } form-control transparent-input`}
                  id="email"
                  name="email"
                  value={contactFormData.email}
                  onChange={handleChange}
                  style={{ color: "white" }}
                  placeholder="Eg. youremail@email.com"
                  required
                />
                {!!errors?.email && (
                  <span className="errorMessage mt-[-5px] ">{errors?.email}</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="phone_number" className="form-label">
                  Phone Number*
                </label>
                <input
                  type="text"
                  className={`${
                    !!errors?.phone ? 'input-error' : ''
                    } form-control transparent-input`}
                  id="phone"
                  name="phone"
                  value={contactFormData.phone}
                  onChange={handleChange}
                  style={{ color: "white" }}
                  placeholder="Enter 10-digit mobile number"
                  required
                />
                {!!errors?.phone && (
                  <span className="errorMessage">{errors?.phone}</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message*
                </label>
                <textarea
                  style={{ minHeight: "150px", color: "white" }}
                  className={` ${
                    !!errors?.message ? 'input-error' : ''
                    } form-control transparent-input`}
                  id="message"
                  name="message"
                  value={contactFormData.message}
                  onChange={handleChange}
                  required
                  placeholder="Your Message"
                ></textarea>
                {!!errors?.message && (
                  <span className="errorMessage -mt-1 ">{errors?.message}</span>
                )}
              </div>
              <div className="mb-3">
                <Captcha handleCaptchaChange={handleCaptchaChange}/>
                {!!errors?.captcha && (
                  <span className="errorMessage">{errors?.captcha}</span>
                )}
              </div>
              <button type="submit" className="btn get-a-quote-btn">
                Get A Quote
              </button>
              {!!errors?.submission && (
                <span className="errorMessage">{errors?.submission}</span>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;