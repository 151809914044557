import {EMAIL_REGREX} from '../constants';

export const emailValidator = email => {
	return EMAIL_REGREX?.test(email);
};

export const htmlToText = html => {
    let temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent;
}
