import React, { useState, useEffect, useRef } from 'react';
import './../../css/Industries.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Card } from 'react-bootstrap';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ServiceManager } from '../../config/serviceManager';
import { MediaUrlBase } from '../../utils/urls';

const SlidePrevButton = () => {
	const swiper = useSwiper();
	return (
	  <button
		type="button"
		className="outline-none me-4 service-button"
		onClick={() => swiper.slidePrev()}
	  >
		<i className="bi bi-arrow-left"></i>
	  </button>
	);
  };
  
  const SlideNextButton = () => {
	const swiper = useSwiper();
	return (
	  <button
		type="button"
		className="outline-none service-button"
		onClick={() => swiper.slideNext()}
	  >
		<i className="bi bi-arrow-right"></i>
	  </button>
	);
  };

const Industries = () => {
	const [scrollLeft, setScrollLeft] = useState(null);
	const [industriesData, setIndustriesData] = useState([]);
 	const [industriesTitle, setIndustriesTitle] = useState([]);
	const cardScrollContainerRef = useRef(null);
	const cardWidth = 2000;
	const scrollIntervalRef = useRef(null);

	const handleCardMouseEnter = () => {
		clearInterval(scrollIntervalRef.current);
	};

	const handleCardMouseLeave = () => {
		startAutoScroll();
	};

	const startAutoScroll = () => {
		scrollIntervalRef.current = setInterval(() => {
			if (cardScrollContainerRef.current) {
				const newScrollLeft = scrollLeft + cardWidth;
				cardScrollContainerRef.current.scrollTo({
					left:
						newScrollLeft >= cardScrollContainerRef.current.scrollWidth
							? 0
							: newScrollLeft,
					behavior: 'smooth',
				});
				setScrollLeft(
					newScrollLeft >= cardScrollContainerRef.current.scrollWidth
						? 0
						: newScrollLeft,
				);
			}
		}, 5000);
	};

	useEffect(() => {
		startAutoScroll();

		return () => {
			clearInterval(scrollIntervalRef.current);
		};
	}, [scrollLeft]);


	useEffect(() => {
		const getIndustriesTitleData = async () => {
			try {
				const industriesResponse = await ServiceManager.getIndustriesSliderTitle(1);
				if (!industriesResponse.hasError) {
					const data = industriesResponse?.response?.data;
					if (data) {
						setIndustriesTitle(data[0]);
					}
				}
			} catch (error) {
				console.error(error);
			}
		};
		getIndustriesTitleData();
	}, []);

	useEffect(() => {
		const getIndustriesData = async () => {
			try {
				const industriesResponse = await ServiceManager.getIndustriesSliderData(1);
 				if (!industriesResponse.hasError) {
					const data = industriesResponse?.response?.data;
					if (data?.length) {
						const newResponse = data
                       const sortedResponse = newResponse?.sort((a, b) => {
                       return a.order_by - b.order_by;
            })
						setIndustriesData(sortedResponse);
					}
				}
			} catch (error) {
				console.error(error);
			}
		};
		getIndustriesData();
	}, []);

	return (
		!!industriesData?.length && (
			<div className="container-fluid indus-containers">
				{!!industriesTitle && <Row className="text-center">
					<div className="differentiators-header pt-5" dangerouslySetInnerHTML={{ __html: industriesTitle?.title }}></div>
				</Row>}

				<div className="container-fluid indus-spacing">
					<Swiper
						spaceBetween={0}
						centeredSlides={true}
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
							pauseOnMouseEnter: true,
						}}
						loop={true}
						navigation={false}
						modules={[Autoplay]}
						className="industries"
						breakpoints={{
							'@0.00': {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							'@0.75': {
								slidesPerView: 1,
								spaceBetween: 20,
							},
							'@1.00': {
								slidesPerView: 3,
								spaceBetween: 40,
							},
						}}>
						{industriesData?.map((industry, index) => (
							<SwiperSlide key={industry?.id}>
								<Card
									className="card-scrollll card-indus"  style={{width:"75%"}}		
									onMouseEnter={handleCardMouseEnter}
									onMouseLeave={handleCardMouseLeave}>
									<Card.Body className="card-body-indus">
										<Card.Title
											className="card-title-indus pb-3"
											dangerouslySetInnerHTML={{ __html: industry?.title }}
										/>
										<div className='' style={{height:"auto"}}>
											<Card.Text
												className="card-text-indus mb-2"
												dangerouslySetInnerHTML={{ __html: industry?.description }}
											/>
										</div>
										<div className="position-relative">
											<Card.Img
												src={`${MediaUrlBase}${industry?.image}`}
												alt={`Industry ${industry?.
													alt_img_text}`}
												className="card-img-top-indus"
												loading="lazy"
											/>
											<div
												className="indus-count-num"
												style={{ fontWeight: '600' }}>
												{index + 1}
											</div>
										</div>
									</Card.Body>
								</Card>
							</SwiperSlide>
						))}

						<div className=" text-center arrow-icons-industry-lr justify-content-center d-flex pt-3">
							<SlidePrevButton />
							<SlideNextButton />
						</div>
					</Swiper>
				</div>
			</div>
		)
	);
};

export default Industries;
