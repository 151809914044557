import React from "react";
import Style from "../imagecards.module.scss";
import { Link } from "react-router-dom";

const LTRCard = ({
	title,
	description,
	image,
	link
}) => {
	return (
		<div className={`row image-cards-container`}>
			<div className={`col-12 col-lg-6 image-card-image`}>
				<img src={image} alt="" className="images" />
			</div>
			<div className={`col-12 col-lg-6 image-card-content`}>
				<div className={`${Style["content"]}`}>
					<h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
					<div style={{ wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: description }}>
					</div>
					{link && link !== "" && (
						<div>
							<Link
								to={link}
								className={`d-flex  align-items-center ${Style["read-more-ltr"]}  `}
								style={{ color: "inherit", textDecoration: "none", marginTop: '10px' }}
							>
								Learn more
								<div
									className={` d-flex justify-content-center align-items-center ms-3 ${Style["icon-arrow"]}`}
								>
									<i className="bi bi-arrow-right "></i>
								</div>
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default LTRCard;
