import React, {useEffect, useState} from 'react';
import '../../css/ContactCss/ContactUsHeader.scss';
import './../../css/ModalCss/Modal.module.scss';
import GetAQuote from '../GetAQuote';
import {ServiceManager} from '../../config/serviceManager';
import TitleComponent from '../titleComponent';


const ContactUs = () => {
  const [contactHeader, setContactHeader] = useState({});
 console.log("contactHeader ", contactHeader);

  useEffect(() => {
    const getContactHeader = async () => {
      try {
        const contactHeaderResponse = await ServiceManager.getContactHeader();
         if (!contactHeaderResponse?.hasError) {
          const data = contactHeaderResponse?.response?.data;
          setContactHeader(data);
        } else {
          throw new Error('Error in fetching career header');
        }
      } catch (error) {
        console.error(error);
      }
    };
    getContactHeader();
  }, []);
  return (
    <>
      {!!Object.keys(contactHeader) && (
        <TitleComponent
          title={contactHeader?.header_title}
          image={contactHeader?.header_image}
          description={contactHeader?.header_description}
        />
      )}
      <GetAQuote />
    </>
  );
};

export default ContactUs;
