import React, {useEffect, useState} from 'react';
import '../../css/ChooseUs.css';
import {ServiceManager} from '../../config/serviceManager';
import { MediaUrlBase } from '../../utils/urls';

const ChooseUs = () => {
  
  // const imageUrl = `/images/ChooseUs/Ship-one-bg.webp`;

  const[recievedChooseUsData,setRecievedChooseUsData]= useState();
 
  useEffect(() => {
    const getChooseUsData= async () => {
      try {
        const chooseUsResponse = await ServiceManager.getChooseUsDetails(
           1,
        );
        
              
        if (!chooseUsResponse.hasError) {
          const data = chooseUsResponse?.response?.data;
          if(data){
            setRecievedChooseUsData(data[0])
          }
                   
        }else {
          throw new Error("Error in getting aboutUs header data");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getChooseUsData();
  }, []);


  return (
    <>
     {recievedChooseUsData && (
      <div 
      className="container-fluid chooseus-wh choose-us-main-contain"
      style={{backgroundImage: `url(${MediaUrlBase}${recievedChooseUsData.bg_image})`}}>
      <div className="row">
        <div className="col-lg-6 flex items-center">
          <div className="chooseus-h">
            <div className="chooseus-h-one" dangerouslySetInnerHTML={{__html:recievedChooseUsData.title}}></div>
          </div>
          <div className="chooseus-h2 pt-2"></div>
          <div >
            <div className="chooseus-parag" dangerouslySetInnerHTML={{__html:recievedChooseUsData.subtitle}}></div>
          </div> 
        </div>
      </div>
    </div>
     )
    }</>
   
  );
};

export default ChooseUs;
